import React, { useContext } from "react";
import './Day.scss'
import moment from "moment";
import Context from "../Context";
import { deletar, get, update } from "../f_aux/fetch";

function Day(props) {
    const { dialog, user, isEdit, setIsEdit, setRefresh, categ, group, statusServico, unidade } = useContext(Context)
    const width = 100 / props.card.length + "%"
    const style = {
        marginLeft: props.card.z == 2 ? '50%' : '',
        top: parseInt(props.card.indice) / 1.2 + 'px',
        height: props.card.duracao > 59 ? (parseInt(props.card.duracao) / 1.2) + 'px' : '60px',
        width: 100 / parseInt(props.card.z) + '%',
        zIndex: props.card.z
    }


    function desbloquear() {
        if (props.card.usuario == user || parseInt(group) < 3 || parseInt(group) === 12) {
            let resp = window.confirm(`${props.card.usuario}: "${props.card.descr2}". Deseja desbloquear esse horário?`)
            if (resp) {
                deletar(`/deleteEvent/${props.card.idevent}`, setRefresh)
            }
        } else {
            window.alert(`${props.card.usuario}: "${props.card.descr2}".`)
        }

    }

    function getColor1(sala) {
        return (categ.find((element) => element.id == sala) ? categ.find((element) => element.id == sala).color : '#111')
    }

    function insertEncaixe(isEdit) {
    //    if ((props.card.next == 1 && props.card.empresa != 1 ) || user == 'fernanda' || user == 'fellipe' || props.card.doctor_iddoctor == 55) {    
            let resp = window.confirm(`Confirma o encaixe às ${moment('2020 01 01 ' + props.card.start_time).add(20, 'minutes').format('HH:mm')} ?`)
            if (resp) {

                isEdit.start_date = props.card.start_date.substr(0, 10)
                isEdit.start_time = moment('2020 01 01 ' + props.card.start_time).add(20, 'minutes').format('HH:mm')
                isEdit.end_time = moment('2020 01 01 ' + props.card.end_time).add(20, 'minutes').format('HH:mm')
                isEdit.doctor_iddoctor = props.card.doctor_iddoctor
                isEdit.empresa = props.card.empresa
                isEdit.categ = 1
                function then(resp) {
                    if (resp.exists) {
                        window.alert(`Horário já reservado para ${resp.exists.title}`)
                        inputClose(false, 0)
                        setIsEdit(false)
                    } else {
                        update(`/editar/event/idevent/${isEdit.idevent}`, { mens_enviada: 1, data_enviado: '0000-00-00 00:00:00', data_enviado1: '0000-00-00 00:00:00' }, then2)
                        function then2() {
                            inputClose('Cadastrado', 0)
                            setIsEdit(false)
                        }
                    }
                }
                update(`/updateEvent`, isEdit, then)




            }
      //  } else {
     //       window.alert('Encaixe não permitido')
      //  }

    }

    function alterarAgenda(isEdit) {

        isEdit.start_date = props.card.start_date.substr(0, 10)
        isEdit.start_time = props.card.start_time
        isEdit.end_time = props.card.end_time
        isEdit.doctor_iddoctor = props.card.doctor_iddoctor
        isEdit.categ = 1
        isEdit.reagendar = true
        isEdit.user = user

        function then(resp) {
            if (resp.exists) {
                window.alert(`Horário já reservado para ${resp.exists.title}`)
                inputClose(false, 0)
                setIsEdit(false)
            } else {
                update(`/editar/event/idevent/${isEdit.idevent}`, { mens_enviada: 1, data_enviado: '0000-00-00 00:00:00', data_enviado1: '0000-00-00 00:00:00' }, then2)
                function then2() {
                    inputClose('Cadastrado', 0)
                    setIsEdit(false)
                }
            }
        }
        update(`/updateEvent`, isEdit, then)



    }

    function anularTransferencia(user) {
        window.alert('Horário bloqueado por ' + user)
        setIsEdit(false)
    }

    function inputClose(x) {
        setRefresh(props.card)
        dialog(x, 0)


    }

    function Aberto({ item, index }) {
        return (
            <div
                onClick={() =>
                    isEdit ?
                        alterarAgenda(isEdit)
                        : props.setInput(2, item, '')
                } className={`hourAgenda hourAgendaDisp`} style={style} key={index}
            >

                <p style={{ color: '#4c95e2' }} >
                    {item.start_time.substr(0, 5)}
                </p>

            </div>

        )
    }

    function Intervalo({ item, index }) {

        return (
            <div key={index} className="hourAgenda hourBloqueio" style={style}>


                <div key={index} style={{ minWidth: width, backgroundColor: props.card.length > 1 && '#fff' }}         >
                    Almoço
                </div>
            </div>

        )
    }

    function Bloqueio({ item, index }) {
        return (
            <div
                style={style}
                className={`hourAgenda hourBloqueio`} key={index}
                onClick={() =>
                    isEdit ?
                        anularTransferencia(item.user) :
                        desbloquear()
                }
            >
                <div key={index} style={{ minWidth: width, backgroundColor: props.card.length > 1 && '#fff' }}         >
                    {item.descr2 || 'Bloqueio'}
                </div>
            </div>
        )
    }



    function Agendado({ item, index }) {


        return (

            <div
                style={style}
                className={`hourAgenda hourAgendado`} key={index}

            >
                <div style={{
                    background: getColor1(item.categ),
                    borderRadius: '7px',
                    /*border: user === item.usuario ? '4px solid #20a75d' : ''*/
                }} className='hourHover'
                    onClick={() =>
                        isEdit ?
                            insertEncaixe(isEdit)
                            : props.setInput(2, item, '')}>
                    {isEdit ? <span>Selecione o horário de destino</span> : <>
                        <span className={user === item.usuario ? '' : ''}>
                            {item.title || ''}
                        </span>
                        <span style={{}} >
                            {`${item.start_time} ~ ${item.end_time}`}
                        </span>

                        <span style={{ display: 'flex' }}>
                            <label className="userEventLabel" style={item.usuario === user ? { fontSize: '15px', color: '#1345cf' } : { color: '#777' }}>
                                {item.usuario}
                            </label>
                            {item.servico ?
                                <div className="servicoEvent" style={{ background: statusServico.find((element) => element.id == item.servico)?.color }}>

                                </div>
                                :
                                item.descr
                            }
                        </span>



                    </>}



                </div>


            </div>
        )
    }



    function Indisp() {
        return (
            <div
                className="hourAgenda hourAgendaIndisp"
                key={props.index}
                style={style}
                onClick={() => props.setInput(2, props.card, '')}
            >

            </div>
        )
    }
    function Past() {
        return (
            <div
                className="hourAgenda hourAgendaIndisp"
                key={props.index}
                style={style}
                onClick={() => props.setInput(2, props.card, '')}
            >

            </div>
        )
    }

    return (
        <div className="cards" >

            {props.card && props.card.patients_idpatient ?
                props.card.categ == 0 ?
                    <Bloqueio item={props.card} />
                    : <Agendado item={props.card} />
                : props.card && (props.card.start_date == moment().format('YYYY-MM-DD') && props.card.start_time > moment().format('HH:mm') || props.card.start_date > moment().format('YYYY-MM-DD')) ?
                    props.card.categ == 9 ?
                        <Aberto item={props.card} />
                        : props.card.disp == 2 ? <Intervalo item={props.card} />
                            : <Indisp />
                    : props.card.disp == 2 ? <Intervalo item={props.card} />
                        : <Past />
            }

        </div>
    )
} export default Day
