import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import DayForm from '../b_forms/DayForm'
import moment from "moment";
import { Modal } from '../../a_model/Modal'

import { Dialog } from "../c_layouts/Dialog";

import { MdRoomPreferences, MdOutlineColorLens, MdArrowForward, MdEditNote, MdGroups, MdOutlinePersonPin } from 'react-icons/md'
import MonthCalendar from "../c_layouts/MonthCalendar";
import Context from '../Context'
import { produce } from 'immer'
import { get } from "../f_aux/fetch";
import { Reagendar } from "../c_layouts/Reagendar";
import { getTheme } from '../../a_model/Theme'
import { Custom } from "../c_layouts/Custom";
import Clientes from "./Clientes";
import Diario from "./Diario";
import EditA from "./EditA";

function Calendar() {
  const { datap } = useParams()
  const { empresap } = useParams()
  const { userp } = useParams()
  const { groupp } = useParams()
  const { unidadep } = useParams()
  const user = userp.toLowerCase() || localStorage.getItem('login').toLowerCase()
  const empresa = empresap || localStorage.getItem('empresa')
  const group = groupp || localStorage.getItem('group')
  const unidade = unidadep || localStorage.getItem('empresa')
  const [agenda, setAgenda] = useState([])
  const [currentDay, setCurrentDay] = useState(datap ? moment(datap.split('-')).clone().locale("pt-br").subtract(1, 'month') : moment().locale("pt-br"));
  const [currentFormat, setCurrentFormat] = useState(0)
  const [currentModal, setCurrentModal] = useState(0)
  const [currentForm, setCurrentForm] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [refresh, setRefresh] = useState('')
  const [tema, setTema] = useState(0)
  const [reagendar, setReagendar] = useState([])
  const [categ, setCateg] = useState([])
  const [users, setUsers] = useState([])
  useEffect(() => {

    setTema(localStorage.getItem('tema'))
    get(`/buscarTema/${user}`, then)
    function then(resp2) {
      localStorage.setItem('tema', resp2.tema)
      setTema(resp2)
    }
    if (localStorage.getItem('user') != user || localStorage.getItem('empresa') != empresa) {
      localStorage.setItem('user', user)
      localStorage.setItem('empresa', empresa)
      localStorage.setItem('group', group)
      localStorage.setItem('unidade', unidade)

    }
    if (users.length == 0) {
      get(`/getUsers/${empresa}`, setUsers)

    }
    if (categ.length == 0) {
      get(`/getCateg`, setCateg)

    }
    if (!localStorage.getItem('empresa')) {
      localStorage.setItem('empresa', empresap)
      localStorage.setItem('login', userp)
    }

    if (group == 2) {
      //setCurrentFormat(user)
    }

  }, [])
  useEffect(() => {

    if (currentFormat) {
      ////console.log(`/getEventSemana/${currentDay.format('YYYY-MM-DD')}/${currentFormat}/${unidade}`)
      get(`/getEventSemana/${currentDay.format('YYYY-MM-DD')}/${currentFormat}/${unidade}`, setAgenda)
    } else {
      get(`/getAgendaEvent/${currentDay.format('YYYY-MM-DD')}/${empresa}/${unidade}/${group}`, setAgenda)
    }
    get(`/getReagendarEvent/${moment().format('YYYY-MM-DD')}/${empresa}/${unidade}/${group}`, setReagendar)
  }, [currentDay, refresh, currentFormat])



  function subtractA(index) {
    setAgenda(produce(agenda, draft => {
      draft.splice(index, 1)
    }))
  }

  const [modalIsOpen, setIsOpen] = useState(false)

  function handleCloseModal() {
    setIsOpen(false)
  }

  function handleOpenModal(value) {
    setCurrentModal(value)
    setIsOpen(true)
  }

  function mudarDayForm(doctor) {
    if (doctor) {
      //setCurrentFormat(1)
      get(`/getEventSemana/${currentDay.format('YYYY-MM-DD')}/${doctor}`, setAgenda)
    } else {

      setAgenda(false)
      get(`/getAgendaEvent/${moment().format('YYYY-MM-DD')}/${empresa}`, setAgenda)
      setCurrentFormat(0)
      setCurrentDay(moment())

    }
  }

  const authUsers = [
    'dradebora',
    'carol',
    'ricardo',
    'fellipe',
    'renata',
    'zaira',
    'nicolly',
    'gerusa',
    'isabelle_p',
    'isabela',
    'cintia',
    'fernanda'
  ]

  function HoverDropdown() {
    return (
      <ul className="dropdown-menu" >
        <li style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div onClick={() => setCurrentForm(1)} style={{ paddingRight: '100px' }}>
            <MdEditNote color={getTheme(tema.tema, 0)} />
            <span> Diário</span>
          </div>
          {reagendar.length > 0 && <div onClick={() => handleOpenModal(1)} id='diarioIcon' style={{ background: getTheme(tema.tema, 2) }}>{reagendar.length}</div>}
        </li>
        <li onClick={() => setCurrentForm(2)}>
          <MdOutlinePersonPin color={getTheme(tema.tema, 0)} />
          <span>
            Clientes
          </span>
        </li>
        { authUsers.find((i)=>i == user) &&
          <li onClick={() => setCurrentForm(3)}>
            <MdGroups color={getTheme(tema.tema, 0)} />
            <span>Avaliadoras</span>
          </li>}
        <li onClick={() => handleOpenModal(0)}>
          <MdOutlineColorLens color={getTheme(tema.tema, 0)} />
          <span> Personalizar</span>
        </li>
      </ul>
    );
  }

  const legenda = [
    { nome: 'Agendado', color: '#A18AF4' },
    { nome: 'Confirmado', color: '#348EE3' },
    { nome: 'Reagendar', color: '#DECA64' },
    { nome: 'Cancelado', color: '#8B0000' },
    { nome: 'Não veio', color: '#E08039' },
    { nome: 'Atendido', color: '#F593AF' },
    { nome: 'Venda Fechada', color: '#DC61E0' },
    { nome: 'Retorno', color: '#B8C4DB' },

  ]

  const statusServico = [
    { id: '0', nome: 'Sem voucher', color: '' },
    { id: '1', nome: 'Aniversário', color: '#9719b1' },
    { id: '2', nome: 'Voucher $200', color: '#5c6cc9' },
    { id: '3', nome: 'Vale presente', color: '#ce2fb3' },
    { id: '4', nome: 'Experiência digital', color: '#039734' },
    { id: '5', nome: 'Voucher Aniversário', color: '#A1402F' },

]

  function Legenda({legenda}) {
    return (
      <div id='legenda'>
        {legenda.map((item) =>
          <div>
            <div style={{ background: item.color }}>

            </div>
            <span>
              {item.nome}
            </span>
          </div>
        )}
      </div>
    )
  }

  const modals = [
    <Custom />,
    <Reagendar list={reagendar} />
  ]

  function mudar(value) {
    setCurrentDay(value)
  }


  const forms = [
    '',
    <Diario />,
    <Clientes />,
    <EditA />,
    <categ />
  ]


  const [text, setText] = useState(false)

  function dialog(text, type) {
    setText({ text: text, type: type })
  }

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Limpa o evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <Context.Provider value={{
      statusServico,
      currentFormat,
      setCurrentFormat,
      empresa,
      unidade,
      group,
      user,
      users,
      count: agenda.length,
      dialog,
      currentDay,
      setCurrentDay,
      refresh,
      setRefresh,
      isEdit,
      setIsEdit,

      subtractA,
      agenda,
      tema: tema,
      tema2: tema,
      setTema,
      categ,
      setCurrentForm,
      setIsOpen
    }}>
      {currentForm < 1 ?

        windowSize.width > 600 ?
          <div className="agenda" >

            <div className='dayMenu1'>
              <MonthCalendar tema={tema} currentDay={currentDay} mudar={mudar} />

              <HoverDropdown />
              {agenda[0] && agenda[0].week &&
                <div id='doctorLabel'>

                  {agenda[0].agenda[0].namedoctor}

                </div>
              }
              <div style={{display:'flex'}}>
              <Legenda legenda={legenda}/>
              <Legenda legenda={statusServico}/>
              </div>
            </div>

            <div className='dayPage1'>
              <DayForm />
            </div>

            <Modal
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
            >
              {modals[currentModal]}
            </Modal>
          </div>
          :
          <div>
            ''
          </div>
        :
        forms[currentForm]
      }

      <Dialog value={text} />

    </Context.Provider>
  )
}

export default Calendar
