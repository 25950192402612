import React, { useEffect, useContext, useState, useRef } from "react";
import { Context } from "../e_contexts/AuthContext";
import { get, update } from "../../calendar/f_aux/fetch";
import "./Form.scss"
import gif from '../../img/carregando1.gif';
import { MdSend } from "react-icons/md";
import moment from "moment";
import semFoto from '../../img/sem-foto.png';
import { toDate } from "../../a_model/functions";

export default function Form() {
    const { cardForm, setCardForm, statusList, atendente } = useContext(Context)
    const [evos, setEvos] = useState({ anteriores: [], atual: [], foto: false })
    const [isKey, setIsKey] = useState(35)
    const [foto, setFoto] = useState(gif)

    const [project, setProject] = useState
        ({
            id: cardForm.assinado,
            evolucao: '',
            data_evolucao: moment().subtract(3, 'hours').format("YYYY-MM-DD HH:mm")
        })


    useEffect(() => {
        getEvos(false)
       get(`/getFotoEvos/${cardForm.id_cliente}`, setFoto)
    }, [cardForm])
    function getEvos(resp) {
        function then(resp) {
            setEvos(resp)
            
        }
        get(`/getEvos/${cardForm.id_cliente}/${cardForm.id_venda_sub}/${cardForm.chave}`, then)
        if (resp) {
            setCardForm({ ...cardForm, ['status']: 8 })
        }
    }

    const buttonEvoRef = useRef(null);

    useEffect(() => {
        // Focar no botão quando o componente é montado
        if (buttonEvoRef.current) {
            buttonEvoRef.current.focus();
        }

    }, []);
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

        e.target.value.length < 28 && isKey != 35 ?
            setIsKey(35)
            : e.target.value.length < 54 && e.target.value.length > 27 && isKey != 60 ?
                setIsKey(60)
                : e.target.value.length < 81 && e.target.value.length > 53 && isKey != 90 ?
                    setIsKey(90)
                    : e.target.value.length < 100 && e.target.value.length > 80 && isKey != 120 ?
                        setIsKey(120) : console.log('')
    }

    function copiar(evolucao) {
        setProject({ ...project, ['evolucao']: evolucao })
        setIsKey(120)
    }

    function evoluir(up) {
        if (up.id > 0) {
            if (up.evolucao && up.evolucao.length > 0) {
                update(`/evoluir`, up, getEvos)
            } else {
                window.alert("Evolução vazia")
            }
        } else {
            window.alert("Erro do sistema, informe o desenvolvedor!")
        }
    }



    function EvoCard({ evo, index }) {
        const [project2, setProject2] = useState({ id: evo.id, evolucao: evo.evolucao, data_evolucao: evo.data_evolucao })
        const [isKey2, setIsKey2] = useState(35)
        const [state, setState] = useState(0)

        function handleChange2(e) {
            setProject2({ ...project2, [e.target.name]: e.target.value })

            e.target.value.length < 28 && isKey2 != 35 ?
                setIsKey2(35)
                : e.target.value.length < 54 && e.target.value.length > 27 && isKey2 != 60 ?
                    setIsKey2(60)
                    : e.target.value.length < 81 && e.target.value.length > 53 && isKey2 != 90 ?
                        setIsKey2(90)
                        : e.target.value.length < 100 && e.target.value.length > 80 && isKey2 != 120 ?
                            setIsKey2(120) : console.log('')
        }

        const line = [
            <span onClick={() => copiar(evo.evolucao)}>{evo.evolucao}</span>,
            <div className="inputForm2">
                <textarea
                    style={{ height: isKey2 + 'px' }}
                    cols='27'
                    className="textAreaEvo"
                    name='evolucao'
                    placeholder="Inserir evoluçao..."
                    value={project2.evolucao}
                    onChange={(e) => handleChange2(e)}
                />

                <button
                    className="buttonEvo"
                    onClick={() => evoluir(project2)}
                >
                    <MdSend />
                </button>
            </div>
        ]

        return (
            evo.evolucao &&
            <div key={index}>

                <p>{toDate(evo.data_evolucao)} - {evo.nome_atendente}:
                    {evo.login === atendente.login ? <button onClick={() => setState(state === 0 ? 1 : 0)}>{state === 0 ? 'editar' : 'cancelar'}</button> : ''}
                </p>
                {line[state]}
            </div>
        )
    }

    return (
      
            <div id="mobileForm">
                <header>
                    <div>
                        <p>
                            Cliente: <span>{cardForm.nome_cliente}</span>
                        </p>
                        <p>
                            Serviço: <span>{cardForm.nome_procedimento}</span>
                        </p>
                        <p>
                            Região: <span>{cardForm.regiao}</span>
                        </p>
                        <div style={{ display: 'flex' }}>

                            <p>
                                RP:<span> {parseFloat(cardForm.rp).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                            </p>
                            <p>
                                Sessão:<span> {parseInt(cardForm.status) > 2 ? evos.atual.length : parseInt(evos.atual.length) + 1} / {cardForm.qnt_sessao}</span>
                            </p>
                        </div>
                    </div>
                    <img src={foto || semFoto}></img>
                </header>
                <footer>
                    {
                        cardForm.status == 3 ?
                            <div className="inputForm2">
                                <textarea
                                    style={{ height: isKey + 'px' }}
                                    cols='27'
                                    className="textAreaEvo textAreaFooter"
                                    name='evolucao'

                                    placeholder="Inserir evoluçao..."
                                    value={project.evolucao}
                                    onChange={(e) => handleChange(e)}
                                />

                                <button
                                    className="buttonEvo"
                                    onClick={() => evoluir(project)}
                                >
                                    <MdSend />
                                </button>
                            </div>
                            :
                            <span style={{ color: '#fff', borderRadius: '4px', background: statusList[cardForm.status].color }}>{statusList[cardForm.status].status}</span>
                    }
                </footer>
                <body>
                    {evos.atual.length?
                        <div className="evoCards">

                            <header>Pacote Atual: </header>
                            
                            {evos.atual.map((evo, index) =>
                                <EvoCard evo={evo} index={index} />
                            )}
                        </div>
                        : ''
                    }
                    {evos.anteriores.length?
                        <div className="evoCards">
                            <header>Pacotes Anteriores: ({evos.anteriores.length} registro{evos.anteriores.length > 1 && "s"})</header>
                            {evos.anteriores.map((evo) =>
                                <div >
                                    
                                        <p>{evo.data_evolucao && evo.data_evolucao.substr(0, 10).split("-").reverse().join('/')} - {evo.atendente} ({evo.nome}):

                                        </p>
                                        {evo.evolucao ?
                                            <span onClick={() => copiar(evo.evolucao)}>{evo.evolucao}</span>
                                            : <label>(vazio)</label>}
                                    
                                </div>
                            )}
                        </div>
                        :''
                    }

                </body>


            </div>
           

    )
}

