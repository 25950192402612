import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-modal'
import './DiarioForm.css'
import { Card, Form } from '../../a_model/Container'
import { Table, Th } from '../../a_model/Contents'
import Context from "../Context";
import copy from "copy-to-clipboard";
import { Button, InputText, LabelText } from "../../a_model/Input";
import { update } from "../f_aux/fetch";

export default function DiarioForm() {
  const { status, card, setAtt, user, params } = useContext(Context)
  const [modalCard, setModalCard] = useState({})
  const [modalIsOpen, setIsOpen] = useState(false)

  function handleOpenModal(item) {
    setIsOpen(true)
    setModalCard(item)
  }
  function handleCloseModal(value) {
    setIsOpen(false)
    setAtt(value)
  }
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 3
    },
    content: {
      left: '50%',
      top: '50%',
      bottom: '-40%',
      right: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflowY: 'hidden'

      //height: '400px'

    }
  }

  const titles = [
    { width: '', title: 'Data' },
    { width: '', title: 'Hora' },
    { width: '', title: 'Status' },
    { width: '', title: 'Nome' },
    { width: '', title: 'Procedimento' },
    { width: '', title: 'Total' },
    { width: '', title: 'Atendente' },
    { width: '', title: 'RP' },

  ]

  const msg = [
    {id: 1, nome: 'Agendado'},
    {id: 2, nome: 'Mensagem enviada'},
    {id: 99, nome: 'Falha ao enviar'}
  ]


  return (
    card.itens.length > 0 &&
    <Form>
      <Table
        footer={ params.sala == 100 && `Total (${card.qnt}): ${parseFloat(card.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
      >
        <Th array={titles} />
        {card.itens.map((item, index) =>
        (params.sala == 100 || params.sala == item.chave ) &&
          <tr key={index} onClick={() => copy(item.id)}>
            <td> {item.data.substr(2, 8).split('-').reverse().join('/')}</td>
            <td> {item.hora.substr(0, 5)}</td>
            <td onClick={() => copy(item.id)} style={{ color: status[item.status].cor, fontWeight: 500 }}>
              { 
                item.status == 0 ?
                msg.find((element)=> element.id ==item.mens_enviada)?.nome
             : status[item.status]?.nome}
            </td>
            <td style={{ maxWidth: '150px' }} onClick={() => copy(item.id_cliente)}> {item.nome_cliente}</td>
            <td style={{ maxWidth: '150px' }} onClick={() => copy(item.id_venda_sub)}> {item.nome_procedimento}</td>
            <td> {item.qnt_sessao}</td>
            <td> {item.nome_atendente}</td>
            <td onClick={() => handleOpenModal(item)}
              style={{ color: item.status == 5 && '#21a32c', fontWeight: item.status == 5 && '500' }}>
              {parseFloat(item.rp).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </td>


          </tr>

        )}
      </Table>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
      >
        <EditVenda user={user} modalCard={modalCard} handleCloseModal={handleCloseModal} />
      </Modal>
    </Form>
  )
}

function EditVenda({ modalCard, handleCloseModal, user }) {
  const [project, setProject] = useState({ total: modalCard.total, id_venda_sub: modalCard.id_venda_sub })
  useEffect(() => {
    // get(`/atendentes/${params.unidade}`, setAtendentes)
  }, []);
  function handleChange(e) {
    setProject({ ...project, [e.target.name]: e.target.value })

  }

  function updateVenda(item){
    item.user = user
    update('/alterarValorVendasSub', item, handleCloseModal)
  }

  return (
    <Card
      header={<h2>Alterar venda</h2>}
    >
      <LabelText
        header='Cliente:'
      >{modalCard.nome_cliente}</LabelText>
      <LabelText
        header='Procedimento:'
      >{modalCard.nome_procedimento} {modalCard.qnt_sessao} sessões</LabelText>
      <LabelText
        header='RP:'
      >{(parseFloat(project.total) / parseInt(modalCard.qnt_sessao)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</LabelText>
      <InputText
        title="Valor total"
        name='total'
        value={project.total}
        handleOnChange={handleChange}
      />
      <Button
        color="#666"
        click={() => updateVenda(project)}
        value="Alterar"
      />
    </Card>
  )
}