import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import './Pages.css'
import moment from "moment";
import CaixaVenda from "../b_grids/CaixaVenda";
import Context from "../../calendar/Context";
import { Page } from "../../a_model/Container";
import { Header } from "../../a_model/Header";
import { InputDate, Select } from "../../a_model/Input";
import { get } from "../../a_model/fetch";
import RelatorioRecompra from "../b_grids/RelatorioRecompra";
import Voucher from "../b_grids/Voucher";


export default function Caixa() {
    const { unidadep } = useParams()
    const { userp } = useParams()
    const user = userp || localStorage.getItem('login')
    const unidade = unidadep || localStorage.getItem('unidade')
   
    const [data, setData] = useState({ data: moment().format('YYYY-MM-DD'), fim: moment().format('YYYY-MM-DD'), unidade, avaliadora: 100, caixa: 100 })
    const [refresh, setRefresh] = useState({})
    const [params, setParams] = useState({ 
        unidades: [],
        avaliadoras: [],
        caixas: [],})
    const [step, setStep] = useState(0)
    useEffect(() => {
        get(`/buscarSelectVenda/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/${data.caixa}`, setParams)
      
    }, [data, refresh])

    function handleChange(e) {
        //console.log(e.target.value)
        if (e.target.name === 'data' && data.fim < e.target.value) {
            setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
        } else if (e.target.name === 'fim' && e.target.value < data.data) {
            setData({ ...data, ['data']: e.target.value, ['fim']: e.target.value })
        } else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }

    const forms = [
        <CaixaVenda />,
        <RelatorioRecompra/>,
        <Voucher />,
    ]
    const navList = ['Caixa', 'Recompra', 'Voucher']

    const statusServico = [
        { id: '0', nome: 'Sem voucher', color: '' },
        { id: '1', nome: 'Aniversário', color: '#9719b1' },
        { id: '2', nome: 'Voucher $200', color: '#5c6cc9' },
        { id: '3', nome: 'Vale presente', color: '#ce2fb3' },
        { id: '4', nome: 'Experiência digital', color: '#039734' },
      
      ]

    return (
        <Context.Provider value={{ statusServico, unidade, user, data, params, refresh, setRefresh }}>
            <Page>

                <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={[
                        <button>Usuário: {user}</button>
                    ]}
                >

                    <>


                        <InputDate
                            classe='input2'
                            name='data'
                            value={data.data}
                            handleOnChange={handleChange}
                        />
                        <InputDate
                            classe='input2'
                            name='fim'
                            value={data.fim}
                            handleOnChange={handleChange}
                        />
                        {unidade == 1 &&
                            <Select
                                padrao='Unidades'

                                options={params.unidades}
                                name='unidade'
                                value={data.unidade}
                                handleOnChange={handleChange}
                            />}
                        <Select
                            padrao='Avaliadoras'

                            options={params.avaliadoras}
                            name='avaliadora'
                            value={data.avaliadora}
                            handleOnChange={handleChange}
                        />
                        <Select
                            padrao='Caixas'
                            options={params.caixas}
                            name='caixa'
                            value={data.caixa}
                            handleOnChange={handleChange}
                        />
                    </>
                </Header>

                {forms[step]}

            </Page>
        </Context.Provider>
    )
}