import React, { useEffect, useContext, useState } from "react";
import { Context } from "../e_contexts/AuthContext";
import { get } from "../../calendar/f_aux/fetch";
import "./Form.scss"
import moment from 'moment';
import { getUnidade, toCash, toDate } from "../../a_model/functions";
import { NavMobile, RankingBar } from "../../a_model/Contents";


export default function Relatorio() {

    const [nav, setNav] = useState(0)
    const buttonsNav = ['RP', 'Indicações', 'Vendas', 'Ranking']
    const steps = [
        <RP />,
        <Indicacoes />,
        <Vendas />,
        <Ranking />
    ]

    return (
        <>
            <NavMobile buttons={buttonsNav} nav={nav} setNav={setNav}>
                {steps[nav]}
            </NavMobile>

        </>

    )
}

function RP() {
    const { params, login } = useContext(Context)
    const [relatorio, setRelatorio] = useState({ rps: [], atendimentos: 0, rpMes: 0, rpDia: 0 })

    useEffect(() => {
        get(`/getRpMobile/${login}/${params.data}`, setRelatorio)

    }, [params])

    return (
        <>
            <header>
                <span>RP do dia: <label>{toCash(relatorio.rpDia)}</label></span>
                <span>RP do mês: <label>{toCash(relatorio.rpMes)}</label></span>
                <span>Total: <label>{relatorio.atendimentos}</label></span>

            </header>
            <body>
                <div id='gridMobile'>
                    {relatorio.rps.map((card, index) =>

                        <div key={index} className="agendaMobileCard">
                            <header >
                                <div className="">
                                    <span> {toDate(card.data)}</span>
                                </div>

                            </header>
                            <p>
                                Atendimentos: {card.count}
                            </p>
                            <span>
                                RP: {toCash(card.sum)}
                            </span>
                        </div>


                    )}
                </div>
            </body>
        </>
    )

}

function Indicacoes() {
    const { params, login } = useContext(Context)
    const [relatorio, setRelatorio] = useState({ indicacoes: [], atendimentos: 0, total: 0 })

    useEffect(() => {
        get(`/getIndicacoesMobile/${login}/${params.data}`, setRelatorio)

    }, [params])

    return (
        <>
            <header>
                <span>Indicações do mês </span>
                <span>Total: <label>{relatorio.indicacoes.length}</label></span>
                <span>Convertidos: <label>{relatorio.convertidas}</label></span>

            </header>
            <body>
                <div id='gridMobile'>
                    {relatorio.indicacoes.map((card, index) =>

                        <div
                            style={{ background: card.fechamento === 2 ? '#A4FFCD' : '' }}
                            className="indicacaoCard"
                            key={index}
                        >
                            <header>
                                {toDate(card.data)} -  {card.nome_cliente} - {card.id_vendas}
                            </header>
                            {card.itens.map((item, index) =>
                                <div key={index}>
                                    <span style={{ fontWeight: 600, fontSize: '17px', color: item.venda == 'Sim' ? '#088b13' : '' }}>
                                        {item.nome}
                                    </span>
                                    <span>
                                        {toCash(item.total)}
                                    </span>
                                </div>
                            )}
                        </div>


                    )}
                </div>
            </body>
        </>
    )

}

function Vendas() {
    const { params, login } = useContext(Context)
    const [relatorio, setRelatorio] = useState({ vendas: [], total: 0 })

    useEffect(() => {
        get(`/getVendasMobile/${login}/${params.data}`, setRelatorio)

    }, [params])

    return (
        <>
            <header>
                <span>Vendas do Mês </span>
                <span>Total: <label>{relatorio.vendas.length}</label></span>
                <span>Valor: <label>{toCash(relatorio.total)}</label></span>

            </header>
            <body>
                <div id='gridMobile'>
                    {relatorio.vendas.map((card, index) =>

                        <div key={index} className="agendaMobileCard">
                            <header >
                                <div className="">
                                    <span> {card.data.substr(0, 10).split("-").reverse().join('/')}</span>
                                </div>

                                <div className="agendaStatus">
                                    {parseFloat(card.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </div>

                            </header>
                            <p>
                                {card.nome_cliente}
                            </p>
                            {card.nome_procedimento && <span>
                                {card.nome_procedimento}
                            </span>}

                            {card.nota && <label>
                                {card.user}: {card.nota}
                            </label>}
                        </div>


                    )}
                </div>
            </body>
        </>
    )

}

function Ranking() {
    const { params } = useContext(Context)
    const [relatorio, setRelatorio] = useState([])

    useEffect(() => {
        get(`/getRankingVendas/${params.data}`, setRelatorio)

    }, [params])

    return (
        <>
            <header>
                <span>Ranking de vendas do Mês </span>

            </header>

            <RankingBar height='70vh' width='90%'>
                {relatorio.map((item, index) =>

                    <li key={index}>
                        <span>
                            {item.atendente} ({getUnidade(item.unidade)})
                        </span>
                        <div
                            style={{
                                width: (item.count / relatorio[0].count) * 100 + "%",
                                background: '#264bf0'
                            }}>
                            {item.count}
                        </div>
                        <div
                            style={{
                                width: (parseFloat(item.soma) / parseFloat(relatorio[0].maior)) * 100 + "%",
                                background: '#c2a1f7', color: '#333', fontWeight: 500
                            }}>
                            {toCash(item.soma)}
                        </div>

                    </li>
                )}

            </RankingBar>

        </>
    )

}

