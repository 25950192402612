    import React, { useState, useEffect, useContext, useMemo } from "react";
    import { Caixa, Card, Form } from "../../a_model/Container";
    import { Table, Th } from "../../a_model/Contents";
    import { Modal } from '../../a_model/Modal'
    import { get, update } from "../../calendar/f_aux/fetch";
    import { Button, InputDateC, InputText, LabelText2, Select, InputSearch } from "../../a_model/Input";
    import { BiEdit } from "react-icons/bi";
    import Context from "../../calendar/Context";
    import { InputCash } from "../../a_model/Input";
    import './Caixa.scss'
    import { createPDF } from "../f_aux/createPDF";
    import moment from "moment";
    import { toDate } from "../../a_model/functions";

    export default function CaixaVenda() {
        const { user, data, refresh, setRefresh } = useContext(Context)

        const [vendasP, setVendasP] = useState(false)
        const [vendasSub, setVendasSub] = useState(false)
        const [pg, setPg] = useState({})
        const [isEdit, setIsEdit] = useState(false)
        const [project, setProject] = useState({ user })

        const unidades = [
            { id: '0', nome: 'Indefinido' },
            { id: 1, nome: 'Matriz' },
            { id: 3, nome: 'Goiânia Shopping' },
            { id: 4, nome: 'Orion' },
            { id: 5, nome: 'Campinas' },
            { id: 10, nome: 'Altamira' },
            { id: 11, nome: 'Paulinia' },
            { id: 13, nome: 'Brasília' },
        ]
        const [vendas, setVendas] = useState({
            unidades: [],
            avaliadoras: [],
            caixas: [],
            vendas: [],
            sumVendas: 0,
            sumVendasTotal: 0,
            pagamentos: [],
            sumPagamentos: 0,
            crediario: [],
            sumCrediario: 0,
            bonus: [],
            sumBonus: 0,
            recebimentos: [],
            sumRecebimentos: 0,
        })
        useEffect(() => {
            get(`/buscarVenda/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/${data.caixa}`, setVendas)
          
        }, [data, refresh])
        
        useEffect(() => {
            setVendasP(false)
            setVendasSub(false)
        }, [vendas])
        function getVendasPg(forma) {
            get(`/buscarVendasPagamento/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/${data.caixa}/${forma.id_forma}`, setVendasP)
            setPg(forma.nome_forma)
        }

        function qnt(value) {
            return value > 20 || value < 2 ? 'À Vista' : value + 'X'
        }

        const [modalIsOpen, setIsOpen] = useState(false)

        function handleOpenModal(id_os, os) {
            setIsEdit(false)
            get(`/buscarVendasSub/${id_os}`, then)
            function then(resp) {
                setVendasSub({ ...os, ['sumVendasSub']: resp.sumVendasSub, ['vendasSub']: resp.vendasSub, ['pg']: resp.pg })
                setProject({ data: os.data.substr(0, 10), avaliadora: os.avaliadora, caixa: os.caixa, id_venda: id_os })

            }
            setIsOpen(true)
        }
        function handleChange(e) {
            setProject({ ...project, [e.target.name]: e.target.value })

        }

        const vendido = [
            { color: '#DDD' },
            { fontWeight: '500', color: '#169c3e' }
        ]
        function put2() {
            update(`/alterarFechamento`, project, then)
            function then(resp2) {
                setRefresh(resp2)
                setIsEdit(resp2)
                setIsOpen(false)
            }
        }
        function desfazer() {
            update(`/desfazerVenda/${user}`, vendasSub, then)
            function then(resp2) {
                setIsEdit(resp2)
                setRefresh(resp2)
            }
        }

        const titles = [
            { width: '', title: 'Data' },
            { width: '', title: 'Hora' },
            { width: '', title: 'Nome' },
            { width: '', title: 'Unidade' },
            { width: '', title: 'Valor' },
        ]
        const titles1 = [
            { width: '', title: 'Data' },
            { width: '', title: 'Hora' },
            { width: '', title: 'Nome' },
            { width: '', title: 'Valor' },
        ]
        const titles2 = [
            { width: '', title: 'Pagamento' },
            { width: '', title: 'Valor' },
        ]
        const titles3 = [
            { width: '', title: 'Data' },
            { width: '', title: 'Nome' },
            { width: '', title: 'Valor' },
            { width: '', title: 'qnt' },
            { width: '', title: 'Unidade' },

        ]
        const titles4 = [
            { width: '', title: 'Data' },
            { width: '', title: 'Nome' },
            { width: '', title: 'Valor' },
            { width: '', title: 'qnt' },

        ]
        const titles5 = [
            { width: '', title: 'Procedimento' },
            { width: '', title: 'Valor' },
        ]
        const titles6 = [
            { width: '', title: 'Data' },
            { width: '', title: 'Nome' },
            { width: '', title: 'Conta' },
            { width: '', title: 'Valor' },
            { width: '', title: 'Unidade' },
        ]
        const titles7 = [
            { width: '', title: 'Data' },
            { width: '', title: 'Nome' },
            { width: '', title: 'Conta' },
            { width: '', title: 'Valor' },
        ]

        function gerarPDF(){
            function then(result){
            // //console.log(result)
                createPDF(data, result)
            }
            get(`/buscarSumRelatorio/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/${data.caixa}`, then)
        }
        // footer={`Vendas: ${vendas.sumVendas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - Total (${vendas.vendas.length}): ${vendas.sumVendasTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} `}
        return (
            vendas.vendas &&
            <Caixa>
                <div>
                    <h2 style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>Lançamentos <button onClick={() => gerarPDF(data)}>Gerar Relatório</button></h2>
                    <Table
                        footer={`Total: ${vendas.vendas.length}`}
                    >
                        <Th array={data.unidade == 100 ? titles : titles1} />
                        {vendas.vendas.map((dataCard, index) =>
                            <tr key={index} className='hover' onClick={() => handleOpenModal(dataCard.id_venda, dataCard)}>
                                <td className="">{dataCard.data.substr(2, 8).split('-').reverse().join('/')}</td>
                                <td >{dataCard.data.substr(11, 5)}</td>
                                <td style={{ maxWidth: '200px' }}>{dataCard.nome_cliente}</td>
                                {data.unidade == 100 && <td >{unidades.find((element)=> element.id == dataCard.empresa).nome}</td>}
                                <td>{dataCard.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>
                        )}

                    </Table>
                </div>
                <div className="formCaixa">
                    <h2>Formas de Pagamento</h2>
                    <Table
                    height='210px'
                        footer={
                            `Caixa: ${vendas.sumPagamentos && vendas.sumPagamentos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                        }
                    >
                        <Th array={titles2} />
                        {vendas.pagamentos.map(pagamento =>
                            <tr onClick={() => getVendasPg(pagamento)} className='hover'>
                                <td>
                                    {pagamento.nome_forma}
                                </td>
                                <td>
                                    {parseFloat(pagamento.soma).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </td>
                            </tr>

                        )}

                    </Table>

                    {vendas.crediario.length > 0 &&
                        <>
                            <Table
                            
                                footer={
                                    ` À receber: ${vendas.sumCrediario && vendas.sumCrediario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                                }
                            >
                                <Th array={titles2} />
                                {vendas.crediario.map(cred =>
                                    <tr onClick={() => getVendasPg(cred)} className='hover'>
                                        <td>
                                            {cred.nome_forma}
                                        </td>

                                        <td>
                                            {parseFloat(cred.soma).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                    </tr>

                                )}
                            </Table>
                            {(vendas.crediario.length > 0) &&
                                <footer style={{fontWeight:500, fontSize:'18px', padding:'6px 4px', color:'#0b9667'}}>Caixa + À receber: {parseFloat(vendas.sumCrediario + vendas.sumPagamentos).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</footer>}

                        </>}
                    {vendas.bonus.length > 0 &&
                        <>
                            <Table

                            >
                                <Th array={titles2} />
                                {vendas.bonus.map(cred =>
                                    <tr onClick={() => getVendasPg(cred)} className='hover'>
                                        <td>
                                            {cred.nome_forma}
                                        </td>

                                        <td>
                                            {parseFloat(cred.soma).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                    </tr>

                                )}
                            </Table>


                        </>}

                </div>
                {vendasP ?
                    <div><h2 style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>Lançamentos ({pg}) <button onClick={() => setVendasP(false)}>Voltar</button> </h2>
                        <Table
                            footer={`Total (${vendasP.vendas.length}): ${vendasP.sumVendas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                        >
                            <Th array={data.unidade == 100 ? titles3 : titles4} />
                            {vendasP.vendas.map((dataCard, index) =>
                                <tr key={index} className='hover' onClick={() => handleOpenModal(dataCard.id_os, dataCard)}>
                                    <td className="">{dataCard.data.substr(2, 8).split('-').reverse().join('/')}</td>
                                    <td style={{ maxWidth: '200px' }}>{dataCard.nome_cliente}</td>
                                    <td >{dataCard.valor && dataCard.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td>{qnt(dataCard.qnt)}</td>
                                    {data.unidade == 100 && <td >{unidades.find((element)=> element.id == dataCard.empresa).nome}</td>}
                                </tr>
                            )}

                        </Table></div>

                    :

                    vendas.recebimentos.length > 0 &&

                    <div>
                        <h2>Recebimento de parcelas</h2>
                        <Table
                            footer={`Total (${vendas.recebimentos.length}): ${vendas.sumRecebimentos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                        >
                            <Th array={data.unidade == 100 ? titles6 : titles7} />
                            {vendas.recebimentos.map((dataCard, index) =>
                                <tr key={index} className='hover'>
                                    <td className="">{dataCard.data_baixa.substr(2, 8).split('-').reverse().join('/')}</td>

                                    <td style={{ maxWidth: '200px' }}>{dataCard.nome_l}</td>
                                    <td style={{ maxWidth: '100px' }}>{dataCard.descricao}</td>

                                    <td>{parseFloat(dataCard.subtotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    {data.unidade == 100 && <td >{unidades.find((element)=> element.id == dataCard.unidade).nome}</td>}

                                </tr>
                            )}

                        </Table>
                    </div>


                }
                <Modal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                >
                    
                    {vendasSub ?
                        !isEdit ?
                            <Card
                            >

                                {(moment().format('DD/MM/YYYY') == toDate(vendasSub.data) || user == 'fellipe' ) &&
                                    <button onClick={() => setIsEdit(true)} style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                        <BiEdit />
                                    </button>}
                                <header>
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <label>Cliente:<span> {vendasSub.nome_cliente}</span></label>
                                            <label>Código:<span> {vendasSub.id_venda || vendasSub.id_os}</span></label>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <label>Unidade:<span> {unidades.find((element)=> element.id == vendasSub.empresa).nome}</span></label>
                                            <label>Caixa:<span> {vendasSub.caixa}</span></label>
                                            <label>Avaliadora:<span> {vendasSub.avaliadora}</span></label>
                                        </div>
                                    </div>
                                </header>
                                <div>
                                    <Table
                                        height='300px'
                                        footer={
                                            `Total: ${parseFloat(vendasSub.sumVendasSub).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                                        }
                                    >
                                        <Th array={titles5} />
                                        {vendasSub.vendasSub && vendasSub.vendasSub.map((vendaSub, index) =>
                                            <tr key={index} >
                                                <td style={vendido[vendaSub.venda.substr(1, 1) == "i" ? 1 : 0]}>
                                                    {vendaSub.nome_procedimento}
                                                </td>

                                                <td style={vendido[vendaSub.venda.substr(1, 1) == "i" ? 1 : 0]}>
                                                    {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                </td>



                                            </tr>)}
                                    </Table>
                                    <Table>
                                        <Th array={titles2} />
                                        {vendasSub.pg && vendasSub.pg.map((pg, index) =>
                                            <tr>
                                                <td>
                                                    {pg.nome_forma} ({qnt(pg.itens_qnt)})
                                                </td>
                                                <td>
                                                    {parseFloat(pg.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                </td>
                                            </tr>
                                        )}
                                    </Table>
                                </div>
                            </Card>
                            :
                            <Card>

                                <header>
                                    Cliente: {vendasSub.nome_cliente}
                                </header>
                                <div>
                                    <InputDateC
                                        title='Data'
                                        name='data'
                                        value={project.data}
                                        handleOnChange={handleChange}
                                    />
                                    <InputText
                                        title='Avaliadora'
                                        name='avaliadora'
                                        value={project.avaliadora}
                                        handleOnChange={handleChange}
                                    />
                                    <InputText
                                        title='Caixa'
                                        name='caixa'
                                        value={project.caixa}
                                        handleOnChange={handleChange}
                                    />
                                    <Button
                                        color="#444"
                                        value='Salvar'
                                        click={() => put2()}
                                    />
                                </div>
                                <body style={{ height: '200px', overflow: 'auto', marginBottom:'50px', background:'#ffffffcb' }}>
                                    {vendasSub.vendasSub.map((item, index) =>
                                        <LineSub item={item} />
                                    )}


                                    {vendasSub.pg && vendasSub.pg.map((item, index) =>
                                        <RowPg dataCard={item} pg={pg} qnt={qnt} />
                                    )}
                                </body>
                                <footer >
                                    <Button
                                        color='#BA2F25'
                                        value='Desfazer Venda'
                                        click={() => desfazer()}
                                    />
                                </footer>

                            </Card>
                        : ''
                    }
                </Modal>
            </Caixa>

        )
    }

    function LineSub({ item }) {
        const { setRefresh } = useContext(Context)

        const [isEdit, setIsEdit] = useState(true)
        const [project, setProject] = useState(item)

        function handleChange(e) {
            setProject({ ...project, [e.target.name]: e.target.value })
        }
        function put() {
            update(`/alterarFechamento`, project, then)
            function then(resp2) {
                setIsEdit(resp2)
                setRefresh(resp2)


            }

        }



        function editName(item) {
            setProject({ ...project, ['nome_procedimento']: item.nome, ['idproduto']: item.id })
            setIsEdit(true)
        }

        return (
            <>
                {isEdit ?
                    <LabelText2
                        onClick={() => setIsEdit(false)}
                    >
                        {project.nome_procedimento}
                    </LabelText2>

                    :
                    <ProcsBar proc={project.nome_procedimento} setProject={editName} />
                }
                <>
                    <div style={{ display: 'flex' }}>
                        <InputText
                            title='Valor'
                            name='total'
                            value={project.total}
                            handleOnChange={handleChange}
                        />
                        <Button
                            value='Salvar'
                            color='#555'
                            click={() => put()}
                        />
                    </div>
                </>

            </>

        )

    }

    function ProcsBar({ proc, setProject }) {
        const [search, setSearch] = useState('');
        const [procs, setProcs] = useState([{ nome: '', id: '' }])
        useEffect(() => get(`/buscarProcs`, setProcs), []);

        const results = useMemo(() => {

            const lowerBusca = search.toLowerCase()
            return procs.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


        }, [search])
        return (
            <InputSearch
                width='350px'
                search={search}
                placeholder={proc}
                setSearch={setSearch}>

                <ListItem
                    search={search}
                    setProject={setProject}
                    results={results}
                />
            </InputSearch>
        );
    }

    const ListItem = React.memo(({ results, setProject, search }) => {
        return (
            search.length > 2 &&
            <>
                <ul>
                    {results.map((result, index) => (
                        <li
                            key={index}
                            onClick={() => setProject(result)}
                            onFocus={() => setProject(result)} // Adicione um evento de foco
                            tabIndex={0} // Torna o elemento focável
                        >     <span>{result.id} - {result.nome || '-'}</span>
                        </li>
                    ))}
                </ul>
            </>
        );
    });


    function RowPg({ dataCard, pg, qnt }) {
        const [iformas, setIformas] = useState([])

        const [card, setCard] = useState(dataCard)
        const [state, setState] = useState(true)
        const [project, setProject] = useState(dataCard)
        // const [formas, setFormas] = useState([])
        const [parcelas, setParcelas] = useState([{ id: '', nome: '' }])

        function handleChange(e) {
            setProject({ ...project, [e.target.name]: e.target.value })

        }
        useEffect(() => {
            fetch(`${process.env.REACT_APP_CALENDAR}/buscarFormas/1`, {
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
            })
                .then((resp) => resp.json())
                .then((resp2) => {
                    //  //console.log(resp2[1])
                    setIformas(resp2[0])

                })
                .catch(err => console.log(err))
        }, [])

        function pChange(e) {
            handleChange(e)
            //console.log(e.target.value)

            fetch(`${process.env.REACT_APP_CALENDAR}/buscarParcelas/${e.target.value}`, {
                method: "GET",
                heders: {
                    'Content-type': 'application/json',
                },
            })
                .then((resp) => resp.json())
                .then((resp2) => {

                    setParcelas(resp2)
                })
                .catch(err => console.log(err))
        }

        function alterarPg(project) {
            fetch(`${process.env.REACT_APP_CALENDAR}/alterarPg`, {
                method: "PUT",
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(project),
            })
                .then(() => {

                    window.alert('Pagamento alterado!')

                })
                .catch(err => console.log(err))
            //console.log(project)
        }

        function setToEdit() {
            setState(false)
            // //console.log(dataCard)
            if (dataCard.id_forma_pagamento) {
                fetch(`${process.env.REACT_APP_CALENDAR}/buscarParcelas/${dataCard.id_forma_pagamento}`, {
                    method: "GET",
                    heders: {
                        'Content-type': 'application/json',
                    },
                })
                    .then((resp) => resp.json())
                    .then((resp2) => {

                        setParcelas(resp2)
                    })
                    .catch(err => console.log(err))
            } else {
                window.alert('Erro!' + dataCard.id)
            }
        }



        function deletePg(id) {

            let resp = window.confirm(`Confirma a exclusão do método: ${dataCard.nome_forma}?`)
            if (resp) {
                fetch(`${process.env.REACT_APP_CALENDAR}/deletePg/${id}`, {
                    method: "DELETE",
                    headers: {
                        'Content-type': 'application/json',
                    },

                })
                    .then(() => {

                    })
                    .catch(err => console.log(err))
                //console.log(project)
            } else {

                setState(true)
            }

        }


        return (
            card.valor ?
                state ? (
                    // <p onClick={() => setState(false)} className="hover"><label>{parseFloat(card.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </label>- {card.nome_forma}</p>
                    <LabelText2
                        onClick={() => setToEdit(false)}
                    >

                        {project.nome_forma} ({qnt(project.itens_qnt)})

                        {parseFloat(project.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                    </LabelText2>

                ) : (
                    <tr style={{ backgroundColor: '#acaaaa' }}>

                        <td>
                            <Select
                                name='id_forma_pagamento'
                                value={project.id_forma_pagamento}
                                options={iformas}
                                handleOnChange={pChange}

                            />
                        </td>
                        <td>
                            <InputCash
                                name='valor'
                                value={project.valor}
                                handleOnChange={handleChange}
                            />
                        </td>
                        <td>
                            <Select
                                name='itens_qnt'
                                value={project.itens_qnt}
                                options={parcelas}
                                handleOnChange={handleChange}

                            />
                        </td>
                        <td>
                            <Button
                                color='#306dc7'
                                value='Salvar'
                                click={() => alterarPg(project)}
                            />
                            <Button
                                color='#a11a1a'
                                value='Excluir'
                                click={() => deletePg(dataCard.id)}
                            /></td>

                    </tr>
                )
                : ''
        )

    }