import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import './Pages.css'
import moment from "moment";
import Context from "../../calendar/Context";
import { Page } from "../../a_model/Container";
import { Header } from "../../a_model/Header";
import { InputDate, Select } from "../../a_model/Input";
import { get } from "../../calendar/f_aux/fetch";
import { GiCheckMark } from 'react-icons/gi'
import Restantes from "../b_grids/Restantes";


import Acompanhar from "../b_grids/Acompanhar";
import { unidades } from "../../a_model/functions";

export default function Relatorios() {
    const { unidadep } = useParams()
    const { userp } = useParams()
    const user = userp || localStorage.getItem('login')
    const unidade = unidadep || localStorage.getItem('unidade')
    
    const [vendas, setVendas] = useState({
        unidades: [],
        avaliadoras: [],
        vendas: [],
        sumVendas: 0,
        pagamentos: [],
        sumPagamentos: 0,
        crediario: [],
        sumCrediario: 0,
    })
    const [params, setParams] = useState(
        {
            data: moment().format('YYYY-MM-DD'),
            fim: moment().format('YYYY-MM-DD'),
            unidade,
            avaliadora: 100,
            ver: 100
        })
    const [options, setOptions] = useState({
        unidades: [],
        avaliadoras: [],
        vendas: [],
    })
    const [refresh, setRefresh] = useState({})
    const [step, setStep] = useState(0)
    const [current, setCurrent] = useState(true)
    useEffect(() => {
        get(`/buscarVenda/${params.data}/${params.fim}/${params.unidade}/${params.avaliadora}/100`, setVendas)
    }, [params, refresh])

    function handleChange(e) {

        if (e.target.name === 'data' && params.fim < e.target.value) {
            setParams({ ...params, ['data']: e.target.value, ['fim']: e.target.value })
        } else if (e.target.name === 'fim' && e.target.value < params.data) {
            setParams({ ...params, ['data']: e.target.value, ['fim']: e.target.value })
        } else {
            setParams({ ...params, [e.target.name]: e.target.value })
        }
    }
    const optionsData = [
        { id: '0', nome: 'Ativos', data: moment().subtract(3, 'months').format('YYYY-MM-DD'), fim: moment().format('YYYY-MM-DD') },
        { id: 1, nome: 'Ociosos há 1 mês', data: moment().subtract(3, 'months').format('YYYY-MM-DD'), fim: moment().subtract(1, 'months').format('YYYY-MM-DD') },
        { id: 2, nome: 'Ociosos há 2 mêses', data: moment().subtract(3, 'months').format('YYYY-MM-DD'), fim: moment().subtract(2, 'months').format('YYYY-MM-DD') },
        { id: 3, nome: 'Ociosos há 2 meses e 20 dias', data: moment().subtract(3, 'months').format('YYYY-MM-DD'), fim: moment().subtract(80, 'days').format('YYYY-MM-DD') },
        { id: 4, nome: 'Vencidos', data: '2010-01-01', fim: moment().subtract(3, 'months').format('YYYY-MM-DD') },
    ]
    function handleChangeData(e) {
        const item = optionsData.find((element) => element.id == e.target.value)
        setParams({ ...params, ['data']: item.data, ['fim']: item.fim })
    }

    const forms = [
        <Acompanhar />,
        <Restantes />,
    ]
    const navList = ['Acompanhar', 'Crédito']

    function Check() {
        function setThisData() {
            if (current) {
                setCurrent(false)
                setParams({ ...params, ['data']: '2010-01-01' })

            } else {
                setCurrent(true)
                setParams({ ...params, ['data']: params.fim })

            }
        }
        return (
            <div
                className="cardAtend"
                onClick={setThisData}
            >
                {<GiCheckMark style={{ fill: current ? 'rgb(43, 112, 30)' : 'transparent' }} />}
            </div>
        )
    }

    const verList = [
        { id: '1', nome: 'Abertos' },
        { id: '0', nome: 'Resolvidos' }
    ]
    return (
        <Context.Provider value={{ setRefresh, unidade, user, data: params, vendas }}>
            <Page>

                <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={[
                        <button>Usuário: {user}</button>
                    ]}
                >
                    <>
                        {step === 1 ? <Check /> : ''}
                        <InputDate
                            classe='input2'
                            name='data'
                            value={params.data}
                            handleOnChange={handleChange}
                        />
                        <InputDate
                            classe='input2'
                            name='fim'
                            value={params.fim}
                            handleOnChange={handleChange}
                        />
                        <Select
                            padrao='Situação'
                            options={optionsData}
                            name='filter'
                            handleOnChange={handleChangeData}
                        />
                        {unidade == 1 &&
                            <Select
                                padrao='Unidades'
                                options={unidades}
                                name='unidade'
                                value={params.unidade}
                                handleOnChange={handleChange}
                            />
                        }
                    {/*    <Select
                            padrao='Avaliadoras'
                            options={options.avaliadoras}
                            name='avaliadora'
                            value={params.avaliadora}
                            handleOnChange={handleChange}
                        />*/}
                        <Select
                            name='ver'
                            padrao='Status'
                            options={verList}
                            handleOnChange={handleChange}
                            value={params.ver}
                        />
                    </>
                </Header>
                {forms[step]}
            </Page>
        </Context.Provider>
    )
}