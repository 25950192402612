import { useState } from "react"
import RelatorioDiario from "./RelatorioDiario"
import RelatorioGerenciaDiario from "./RelatorioGerenciaDiario"
import { StepsForm } from "../../a_model/Container"

export default function Voucher() {

    const [step, setStep] = useState(0)
    const steps = [
        {
            content: <RelatorioDiario />,
            nome: 'Geral',
            color: '#c7e3fa'
        },
        {
            content: <RelatorioGerenciaDiario />,
            nome: 'Por unidade',
            color: '#c7e3fa'
        },
    ]

    return (
        <div style={{padding: '20px', height: '100%'}}>
        <StepsForm width={'100%'} height={'calc(100vh - 150px)'} steps={steps} step={step} setStep={setStep} />
        </div>
    )
}