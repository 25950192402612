import React, { useState, useEffect, useContext, useMemo } from "react";
import { Caixa } from "../../a_model/Container";
import { Table, Th } from "../../a_model/Contents";
import { toCash, toDate } from "../../a_model/functions";
import { get } from "../../calendar/f_aux/fetch";
import Context from "../../calendar/Context";
import './Caixa.scss'
import { createPDFiecb } from "../f_aux/createPDFiecb";
import copy from "copy-to-clipboard";
import { update } from "../../agendaIecb/f_aux/fetch";
import moment from "moment";
import { Modal } from "../../a_model/Modal";

export default function CaixaVendaIecb() {
    const { data, step } = useContext(Context)
    const [modalIsOpen, setIsOpen] = useState(false)
     const [forma, setForma] = useState(false)
    const [vendas, setVendas] = useState({
        vendas: [],
        sumVendas: 0,
        pagamentos: [],
        sumPagamentos: 0,
        produtos: [],
        sumProdutos: 0,
    })
    const titles = [
        { width: '', title: 'Data' },
        { width: '', title: 'Aluno' },
        { width: '', title: 'Curso' },
        { width: '', title: '*' },
        { width: '', title: 'Valor' },
    ]
    const titles2 = [
        { width: '', title: 'Pagamento' },
        { width: '', title: 'Valor' },
    ]
    const titles3 = [
        { width: '', title: 'Data' },
        { width: '', title: 'Cliente' },
        { width: '', title: 'Produto' },
        { width: '', title: 'Valor' },
    ]
    const tipos = [
        '',
        <div className='tipo' style={{ color: '#d3912e' }}>A</div>,
        <div className='tipo' style={{ color: '#701d97' }}>M</div>
    ]
    useEffect(() => {
        get(`/getCaixaIecb/${data.data}/${data.fim}/${data.curso}/${data.caixa}/${data.categoria}`, setVendas)
    }, [data, step])

    
    function gerarPDF() {
        function then(result) {
            createPDFiecb(data, result)
        }
        get(`/buscarSumRelatorioIecb/${data.data}/${data.fim}/${data.caixa}`, then)
    }

    const formas = [
        { id: 1, nome: 'Débito', parcelas: false },
        { id: 2, nome: 'Dinheiro', parcelas: false },
        { id: 4, nome: 'Crédito', parcelas: true },
        { id: 11, nome: 'Pago pelo link', parcelas: true },
        { id: 17, nome: 'Pix', parcelas: false },
        { id: 3, nome: 'Cheque', parcelas: false },
        { id: 9, nome: 'Promissória', parcelas: true },
    ]

    return (
        vendas.vendas &&
        <Caixa>
            {forma ?
                <CaixaPagamento
                    tipos={tipos}
                    forma={forma}
                    setForma={setForma}
                    data={data}
                />

                : vendas.vendas?.length ?
                    <div>
                        <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            Lançamentos
                            <button onClick={() => gerarPDF()}>
                                Gerar Relatório
                            </button>
                        </h2>
                        <Table
                            footer={`Total: ${vendas.vendas.length}`}
                        >
                            <Th array={titles} />
                            {vendas.vendas.map((dataCard, index) =>
                                <tr key={index} >
                                    <td className="">{toDate(dataCard.data)}</td>
                                    <td 
                                    className="hover" 
                                    style={{ maxWidth: '170px',
                                     color: dataCard.alunoAtivo ? '' : '#c4220d',
                                     }}
                                     onClick={()=> copy(dataCard.telefone)}
                                     >
                                        {dataCard.nome_aluno}</td>
                                    <td style={{ maxWidth: '170px', color: dataCard.aulaAtivo ? '' : '#c4220d' }}>{dataCard.nome_curso}</td>
                                    <td>{tipos[dataCard.tipo]}</td>
                                    <td>{toCash(dataCard.valor_total)}</td>
                                </tr>
                            )}

                        </Table>
                    </div> : ''}
            {vendas.produtos?.length ?
                <div>
                    <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        Produtos
                        {/* <button onClick={() => gerarPDF(data)}>
                        Gerar Relatório
                    </button>*/}
                    </h2>
                    <Table
                        footer={`Total: ${vendas.produtos.length}`}
                    >
                        <Th array={titles3} />
                        {vendas.produtos.map((dataCard, index) =>
                            <tr onClick={() => ''} key={index} className='hover'>
                                <td className="">{toDate(dataCard.data)}</td>
                                <td style={{ maxWidth: '170px' }}>{dataCard.nome_cliente}</td>
                                <td style={{ maxWidth: '170px' }}>{dataCard.nome_produto}</td>

                                <td>{toCash(dataCard.valor_total)}</td>
                            </tr>
                        )}

                    </Table>
                </div> : ''}
            <div className="formCaixa">
                <h2>Formas de Pagamento</h2>
                <Table
                    footer={
                        ` Soma: ${vendas.sumPagamentos && vendas.sumPagamentos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                    }
                >
                    <Th array={titles2} />
                    {vendas.pagamentos.map(pagamento =>
                        <tr className='hover' onClick={() => setForma({id_forma: pagamento.id_forma, nome_forma: formas.find((element) => element.id == pagamento.id_forma).nome})}>
                            <td>
                                {formas.find((element) => element.id == pagamento.id_forma).nome}
                            </td>
                            <td>
                                {parseFloat(pagamento.soma).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </td>
                        </tr>
                    )}
                </Table>
            </div>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            >

            </Modal>
        </Caixa>
    )
}

function CaixaPagamento({ tipos, data, forma, setForma }) {
    const titles = [
        { width: '', title: 'Data' },
        { width: '', title: 'Aluno' },
        { width: '', title: 'Curso' },
        { width: '', title: '*' },
        { width: '', title: 'Valor' },
        { width: '', title: 'qnt' },
    ]
    const [vendas, setVendas] = useState([])
    const [refresh, setRefresh] = useState({})
   
    useEffect(() => {
        if (forma) {
            get(`/getCaixaPgIecb/${data.data}/${data.fim}/${data.curso}/${data.caixa}/${forma.id_forma}/${data.categoria}`, setVendas)
        }
    }, [forma, refresh])
    function desativar(pg) {
        
        if (pg.data.substr(0, 10) == moment().format('YYYY-MM-DD')) {
            let resp = window.confirm(`Cancelar este pagamento? ${pg.id}`)
            if (resp) {
                update(`/updateAtivoIecb/pagamento_iecb/0/${pg.id}`, {}, console.log)
                update(`/editar/alunos_iecb/id/${pg.id_aluno}`, { status: 0 }, then)
            }
            function then() {
                setRefresh(Math.random())
            }
        }else{
            window.alert('Este Pagamento não pode ser desativado')
        }

    }

    return (
        <div>
            <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Lançamentos {forma.nome_forma}
                <button onClick={() => setForma(false)}>
                    Voltar
                </button>
            </h2>
            <Table
                footer={`Total: ${vendas.length}`}
            >
                <Th array={titles} />
                {vendas.map((dataCard, index) =>
                    <tr key={index} className='hover' onClick={()=> desativar(dataCard)}>
                        <td className="">{toDate(dataCard.data)}</td>
                        <td style={{ maxWidth: '170px', color: dataCard.alunoAtivo ? '' : '#c4220d' }}>{dataCard.nome_aluno}</td>
                        <td style={{ maxWidth: '170px', color: dataCard.aulaAtivo ? '' : '#c4220d' }}>{dataCard.nome_curso}</td>
                        <td>{tipos[dataCard.tipo]}</td>
                        <td>{toCash(dataCard.valor_total)}</td>
                        <td>{parseInt(dataCard.qnt) > 1 ? dataCard.qnt + 'X' : 'À Vista'}</td>
                    </tr>
                )}

            </Table>
        </div>
    )
}
