import {  NavButton, NavButton2 } from '../styles/GeralStyle';
import React, { useState } from 'react';
import css from './Header.module.scss'

export function Header(props) {


    const [current, setCurrent] = useState(props.navList[0])
    function setStep(navItem, index){
        props.setStep(index)
        setCurrent(navItem)

    }

    return (

        <header className={css.header}>
            <div className={css.headerBody}>
            {props.children}
            </div>
            <div className={css.headerButtons}>
                <div className={css.headerTopButtons}>
                {props.buttons &&
                props.buttons.map((button, index) =>
                   <div key={'bh'+index}>{button}</div> 
                )}
               </div>
                <div>
                    {props.navList.map((navItem, index) =>
                        <button
                            className={css.navAll}
                            id={navItem === current? css.navOn: css.navOff}
                            key={navItem}
                            step={navItem}
                            current={current}
                            onClick={() => setStep(navItem, index)}
                        >
                            {navItem}
                        </button>
                    )}
                </div>
            </div>
        </header>

    )


}

export function Header2(props) {


    const [current, setCurrent] = useState(props.navList[0])
    function setStep(navItem, index) {
        props.setStep(index)
        setCurrent(navItem)

    }

    return (

        <header className={css.header2}>
            {props.children}

            <div className={css.headerButtons}>
                {props.buttons.map((button) =>
                    <>{button}</>
                )}

                <div>
                    {props.navList.map((navItem, index) =>
                        <button
                            className={css.navAll}
                            id={navItem === current ? css.navOn : css.navOff}
                            key={navItem}
                            step={navItem}
                            current={current}
                            onClick={() => setStep(navItem, index)}
                        >
                            {navItem}
                        </button>
                    )}
                </div>
            </div>
        </header>

    )


}

export function Header3(props) {

    return (

        <header className={css.header3}>
            <div style={{display:'flex', flexDirection:'column'}}>
            {props.children}
            </div>
            <div className={css.headerButtons}>
                {props.buttons.map((button, index) =>
                    <div key={index}>{button}</div>
                )}
            </div>
        </header>

    )

}


export function PageClients(props) {
    return (
        <div className={`pageClients ${props.custom}`} >
            {props.children}
        </div>
    )
}
