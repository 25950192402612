import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Calendar from './calendar/a_pages/Calendar'
import Event from './event/a_pages/Calendar'
import AgendaIecb from './agendaIecb/a_pages/Calendar'

//import Calendar2 from './clinica/a_pages/Calendar'
import EditA from './calendar/a_pages/EditA'
//import EditA2 from './clinica/a_pages/EditA'
import { TesteColor } from './calendar/a_pages/TesteColor'
import CaixaPage from './caixa/a_pages/Caixa'
import Clientes from './calendar/a_pages/Clientes'
//import Clientes2 from './clinica/a_pages/Clientes'
import { Sucess } from './calendar/a_pages/Sucess'
import Leads from "./leads/components/Leads"
import LeadsComercial from "./leadsUser/components/Leads"
import LeadsGerencia from "./leadsComercial/components/Leads"
import LeadsIecb from "./leadsIecb/components/Leads"

import Salas from './calendar/a_pages/Salas'
import Relatorios from './relatorio/a_pages/Relatorios'
//import Salas2 from './clinica/a_pages/Salas'
import Diario from './calendar/a_pages/Diario'
//import Diario2 from './clinica/a_pages/Diario'
import App2 from './App2'
import App3 from './App3'
import Redirect from './Redirect'
import CaixaIecb from './caixa/a_pages/CaixaIecb'
import Parceiros from './parceiros/Home'
import DiarioIecb from './agendaIecb/a_pages/DiarioIecb'
import CursosIecb from './agendaIecb/a_pages/CursosIecb'
import DocentesIecb from './agendaIecb/a_pages/DocentesIecb'
import Checklist from './checklist/Home'

import CadastroIecb from './agendaIecb/a_pages/Cadastro'
import Indicacao from './indicacao/page'
function App() {

  return (

    <Router>
      <Routes>
      <Route exact path='/' element={<Redirect/>}></Route>
      <Route exact path='/checklist/:unidade/:user' element={<Checklist />}></Route>
        
        <Route exact path='/teste' element={<TesteColor />}></Route>
        <Route exact path='/agendaiecb/:userp' element={<AgendaIecb />}></Route>
        <Route exact path='/indicacao/:unidade/:user' element={<Indicacao />}></Route>
        <Route exact path='/parceiros/:empresa' element={<Parceiros />}></Route>
        <Route exact path='/calendar/:unidadep/:userp' element={<Calendar />}></Route>
        <Route exact path='/event/:empresap/:userp/:groupp/:unidadep' element={<Event />}></Route> 
        <Route exact path='/leads/:userp' element={<Leads />}></Route>
        <Route exact path='/leadsIecb/:userp' element={<LeadsIecb />}></Route>
        <Route exact path='/leadsComercial/:empresap/:userp' element={<LeadsComercial />}></Route>
        <Route exact path='/leadsGerencia/:userp/:empresap' element={<LeadsGerencia />}></Route>
        <Route exact path='/clientes/:unidadep/:userp' element={<Clientes />}></Route>
        <Route exact path='/sucess' element={<Sucess />}></Route>
        <Route exact path='/calendar/:unidadep/:datap/:userp' element={<Calendar />}></Route>
        <Route exact path='/EditA/:unidadep/:userp' element={<EditA />}></Route>
        <Route exact path='/salas/:unidadep/:userp' element={<Salas />}></Route>
        <Route exact path='/diario/:unidadep/:userp' element={<Diario />}></Route>
        <Route exact path='/caixa/:unidadep/:userp' element={<CaixaPage />}></Route>
        <Route exact path='/caixaiecb/:userp' element={<CaixaIecb />}></Route>
        <Route exact path='/cadastroiecb/:userp' element={<CadastroIecb />}></Route>
        <Route exact path='/caixa/:userp' element={<CaixaIecb />}></Route>
        <Route exact path='/diarioiecb/:userp' element={<DiarioIecb />}></Route>
        <Route exact path='/cursosiecb/:userp' element={<CursosIecb />}></Route>
        <Route exact path='/docentesiecb/:userp' element={<DocentesIecb />}></Route>
        <Route exact path='/relatorios/:unidadep/:userp' element={<Relatorios />}></Route>
        <Route exact path='/maestro/*' element={<App3 />}></Route>
        <Route exact path='/mobile/*' element={<App2 />}></Route>

      </Routes>
    </Router>



  );
  /**
   * <Route exact path='/calendar2/:unidade/:user' element={<Calendar2 />}></Route>
          <Route exact path='/clientes2/:unidade/:user' element={<Clientes2 />}></Route>
          <Route exact path='/EditA2/:unidade/:user' element={<EditA2 />}></Route>
          <Route exact path='/salas2/:unidade/:user' element={<Salas2 />}></Route>
          <Route exact path='/diario2/:unidade/:user' element={<Diario2 />}></Route>
   * 
   */
}

export default App;