import React, { useState, useEffect, useContext, useMemo } from "react";
import Modal from 'react-modal'
import './DiarioForm.scss'
import { Card, Form } from '../../a_model/Container'
import { Square, Table, Th2 } from '../../a_model/Contents'
import { toDate } from '../../a_model/functions'
import Context from "../Context";
import copy from "copy-to-clipboard";
import { Button, InputText, LabelText } from "../../a_model/Input";
import { update } from "../f_aux/fetch";
import { HiOutlineTrash } from "react-icons/hi";
export default function DiarioForm() {
  const { categ, card, setAtt, user, dialog, params, setParams, statusServico } = useContext(Context)
  const [modalCard, setModalCard] = useState({})
  const [modalIsOpen, setIsOpen] = useState(false)

  function handleOpenModal(item) {
    setIsOpen(true)
    setModalCard(item)
  }
  function handleCloseModal(value) {
    setIsOpen(false)
    setAtt(value)
  }
  function confirmar(idevent) {

    function then() {
      dialog('Confirmado', 0)
      setAtt(Math.random())
    }
    update(`/confirmarEvent/${idevent}`, {}, then)
  }
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 3
    },
    content: {
      left: '50%',
      top: '50%',
      bottom: '-40%',
      right: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflowY: 'hidden'
    }
  }

  function copiar(telefone) {
    copy(telefone)
    // dialog('Copiado', 0)

  }

  const titles = [
    { width: '', coluna: 'start_date', title: `Data` },
    { width: '', coluna: 'data_agendado', title: `Agendado` },
    { width: '', coluna: 'start_time', title: `Hora` },
    { width: '', coluna: 'title', title: `Cliente` },
    { width: '', coluna: false, title: `Telefone` },
    { width: '', coluna: 'doctor_iddoctor', title: `Avaliadora` },
    { width: '', coluna: 'usuario', title: `Usuário` },
    { width: '', coluna: 'categ', title: `Status` },

  ]

  const msg = [
    { id: '0', nome: 'AGENDADO' },
    { id: 1, nome: 'AGENDADO' },
    { id: 2, nome: 'MSG ENVIADA' },
    { id: 99, nome: 'FALHA AO ENVIAR' }
  ]

  const [hover, setHover] = useState(false)

  function cancelar(project) {
    project.start_date = project.start_date.substr(0, 10)
    project.categ = 11
    project.user = user

    function then(resp) {
      if (resp.exists) {
        window.alert(`Erro, Avise o suporte.`)

      } else {

        dialog('Cancelado', 0)
        setAtt(Math.random())

      }
    }
    update(`/updateEvent`, project, then)
  }


  const colors = [
    { id: 0, color: '#9929EA' },
    { id: 1, color: '#EA072A' },
    { id: 2, color: '#aaa' },
    { id: 3, color: '#aaa' },
    { id: 4, color: '#aaa' },
    { id: 5, color: '#13C32F' },
    { id: 6, color: '#111' },
    { id: 7, color: '#ddd' },
    { id: 20, color: '#fff' },
  ]
  return (
    card.length > 0 &&
    <Form>
      <Table
   
      >
        <Th2 array={titles} params={params} setParams={setParams} />
        {card.map((item, index) =>
          <tr key={index}   >

            <td> {toDate(item.start_date)}</td>
            <td> {toDate(item.data_agendado)}</td>

            <td> {item.start_time.substr(0, 5)}</td>
            <td onClick={() => copiar(item.idevent)} style={{position:'relative'}}> 
              {item.title}
              <Square
                size='10px'
                color={statusServico.find((element) => element.id == item.servico)?.color}
              />

            </td>
            <td style={{ maxWidth: '220px' }} onClick={() => copiar(item.telefone)}> {item.telefone}</td>
            <td> {item.namedoctor}</td>
            <td> {item.usuario}</td>

            <td
              onMouseEnter={() => setHover(item.idevent)}
              onMouseLeave={() => setHover(false)}
              style={{ position: 'relative', maxWidth: '150px', minWidth: '150px', fontSize: '14px', fontWeight: 600, color: categ.find((element) => element.id == item.categ).color }}>
              {(item.categ == 1 || item.categ == 6) && params.period === 0 ?
                hover == item.idevent ?
                  <>

                    <button
                      style={{ fontSize: '14px', width: '50%', margin: '-2px 0' }}
                      onClick={() => confirmar(item.idevent)}>
                      Confirmar
                    </button>
                    <button
                      style={{ fontSize: '14px', width: '50%', margin: '-2px 0' }}
                      onClick={() => cancelar(item)}>
                      Cancelar
                    </button>
                  </>

                  : msg.find((element) => element.id == item.mens_enviada)?.nome
                :
                (hover == item.idevent && parseInt(item.categ) < 4) ?
                  <button
                    style={{ fontSize: '14px', width: '90%', margin: -2 }}
                    onClick={() => cancelar(item)}>
                    Cancelar
                  </button>
                  :
                  item.categ == '12' ?
                    <>
                      {categ.find((element) => element.id == item.categ)?.nome}

                      <Square
                        size='10px'
                        color={colors.find((element) => element.id == item.status_naoveio)?.color}
                      />
                    </>

                    :
                    categ.find((element) => element.id == item.categ)?.nome}
            </td>
          </tr>

        )}
      </Table>
      <CountServico />
    </Form>
  )
}

function EditVenda({ modalCard, handleCloseModal, user }) {
  const [project, setProject] = useState({ total: modalCard.total, id_venda_sub: modalCard.id_venda_sub })
  useEffect(() => {
    // get(`/atendentes/${params.unidade}`, setAtendentes)
  }, []);
  function handleChange(e) {
    setProject({ ...project, [e.target.name]: e.target.value })

  }

  function updateVenda(item) {
    item.user = user
    update('/alterarValorVendasSub', item, handleCloseModal)
  }

  return (
    <Card
      header={<h2>Alterar venda</h2>}
    >
      <LabelText
        header='Cliente:'
      >{modalCard.nome_cliente}</LabelText>
      <LabelText
        header='Procedimento:'
      >{modalCard.nome_procedimento} {modalCard.qnt_sessao} sessões</LabelText>
      <LabelText
        header='RP:'
      >{(parseFloat(project.total) / parseInt(modalCard.qnt_sessao)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</LabelText>
      <InputText
        title="Valor total"
        name='total'
        value={project.total}
        handleOnChange={handleChange}
      />
      <Button
        color="#666"
        click={() => updateVenda(project)}
        value="Alterar"
      />
    </Card>
  )
}

function CountServico() {
  const { card, statusServico } = useContext(Context);

  // Memoize list to prevent unnecessary recalculations
  const list = useMemo(() => {
  
      return statusServico.map(servico => ({
          nome: servico.nome,
          itens: card.filter(element => element.servico == servico.id),
          color: servico.color
      }));
  }, [statusServico, card]);

  return (
      <div style={{ display: 'flex', gap: '10px', background:'#fff', padding:'5px' }}>
          {list.map((item, index) => (
              <div style={{ color: item.color, fontWeight: 500 }} key={index}>
                  {item.nome}: {item.itens.length}
              </div>
          ))}
      </div>
  );
}