export function toCash(value) {
    let retorno;

    if (isNaN(parseFloat(value))) {
        retorno = (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    } else {
        retorno = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    return retorno;
}

export function toDate(value) {
    let retorno = ''

    if (value) {
        retorno = (value).substr(0, 10).split('-').reverse().join('/')
    }

    return retorno;
}

export function toDateTime(value) {
    let retorno = ''


    if (value) {
        let data = value.substr(2, 8).split('-').reverse().join('/')
        let hora = value.substr(11, 5)
        retorno = `${data} ${hora}`
    }

    return retorno;
}

export function toTel(telefone) {
    let retorno = ''

    if (telefone) {

        let tel = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))

        retorno = `(${tel.substr(0, 2)}) ${tel.substr(2, tel.length - 6)} ${tel.substr(-4, 4)}`
    }

    return retorno;
}

export function sortState(state, setState, key, order = 'asc') {
    const sortedState = [...state].sort((a, b) => {
        if (a[key] < b[key]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    setState(sortedState);
}

export function sortState2(state, key, order = 'asc') {
    const sortedState = [...state].sort((a, b) => {
        if (a[key] < b[key]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    return sortedState
}

export function getEmpresa(id) {
    const empresas = [

        { id: 1, nome: 'Goiânia' },
        { id: 2, nome: 'Altamira' },
        { id: 5, nome: 'Paulínia' },
        { id: 6, nome: 'Brasília' },
    ]
    if (empresas.find((element) => element.id == id)) {
        return empresas.find((element) => element.id == id).nome
    } else {
        return 'Indefinido'
    }
}

export const unidades = [

    { id: 1, nome: 'Matriz' },
    { id: 3, nome: 'SPA' },
    { id: 4, nome: 'Orion' },
    { id: 5, nome: 'Campinas' },
    { id: 10, nome: 'Altamira' },
    { id: 11, nome: 'Paulinia' },
    { id: 13, nome: 'Brasília' },
]

export function getUnidade(id) {
    
    if (unidades.find((element) => element.id == id)) {
        return unidades.find((element) => element.id == id).nome
    } else {
        return 'Indefinido'
    }
}
