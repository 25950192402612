import React, { useState, useEffect, useContext } from "react";

import Context from "../Context";
import './Clientes.css'
import { Assinaturas } from "../c_layouts/ClientesLayout";
import { Modal } from '../../a_model/Modal'
import { Table, Th } from '../../a_model/Contents'
import { Card, ContainerC } from '../../a_model/Container'
import { get } from "../f_aux/fetch";
import { toDate } from "../../a_model/functions";

export default function VendasForm() {

    const [vendas, setVendas] = useState([])
    const { cliente, } = useContext(Context)
    const [assinaturas, setAssinaturas] = useState([])
    const [sub, setSub] = useState({})
    const [modalIsOpen, setIsOpen] = useState(false)

    function getAss(vendaSub) {
        function then(resp2) {
            setAssinaturas(resp2)
            setSub(vendaSub)
            setIsOpen(true)
        }
        get(`/getAss/${vendaSub.id}`, then)
    }


    useEffect(() => {
        if (cliente.id) {
            get(`/getVendas/${cliente.id}`, setVendas)
        } else {
            setVendas([])
        }
    }, [cliente])
    const titles = [

        { width: '250px', title: 'Procedimento' },
        { width: '100px', title: 'Regiao' },

        { width: '40px', title: 'Valor' },
        { width: '40px', title: 'disp' },
        { width: '40px', title: 'total' },

        { width: '', title: 'Código' },
    ]
    const titles2 = [

        { width: '250px', title: 'Procedimento' },
        { width: '40px', title: 'Valor' },
        { width: '40px', title: 'disp' },
        { width: '40px', title: 'total' },

        { width: '', title: 'Data' },
        { width: '', title: 'Código' },
    ]
    const status = [
        { nome: 'Pendente', cor: '#696969' },
        { nome: 'Vendido:', cor: '#0f8f2f' },
        { nome: 'Vendido:', cor: '#b60000' },
        { nome: 'Indicação', cor: '#1A3BDB' }
    ]

    const unidades = [
        { id: '0', nome: 'Indefinido' },
        { id: 1, nome: 'Matriz' },
        { id: 3, nome: 'Goiânia Shopping' },
        { id: 4, nome: 'Orion' },
        { id: 5, nome: 'Campinas' },
        { id: 10, nome: 'Altamira' },
        { id: 11, nome: 'Paulinia' },
        { id: 13, nome: 'Brasília' },
    ]



    return (
        <>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}>
                <Assinaturas
                    sub={sub}
                    assinaturas={assinaturas}
                />
            </Modal>

            {cliente.nome ?

                <ContainerC >
                    {vendas.vendas0 && vendas.vendas0.length > 0 &&
                        <Card header={<h2>Vendas sem orçamento: </h2>}>
                            <Table>
                                <Th array={titles2} />
                                {!Array.isArray(vendas) && vendas.vendas0.map((vendaSub, index) =>
                                    <tr
                                        key={index}>

                                        <td>
                                            {vendaSub.nome_procedimento}
                                        </td>
                                        <td>
                                            {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                        </td>
                                        <td
                                            className='assButton'
                                            style={{ backgroundColor: status[vendaSub.status].cor }}
                                            onClick={() => getAss(vendaSub)}
                                        >
                                            {vendaSub.status == 0 || vendaSub.SP == 'P' ? '-' :
                                                vendaSub.qnt_sessao - vendaSub.realizadas}
                                        </td>
                                        <td>
                                            {vendaSub.qnt_sessao}

                                        </td>

                                        <td>
                                            {vendaSub.data.substr(0, 10).split('-').reverse().join('/')}
                                        </td>
                                        <td>
                                            {vendaSub.id}
                                        </td>
                                    </tr>
                                )}
                            </Table>
                        </Card>}



                    {!Array.isArray(vendas) ?
                        vendas.vendas1.map((venda, index) =>
                            <Card index={index} header={
                                <>
                                    <div>
                                        <label>Lançado:<span> {toDate(venda.data)}</span></label>

                                        <label style={{ color: status[venda.status].cor, fontWeight: '600', padding: '0 4px', borderRadius: '8px' }}>

                                            {status[venda.status].nome}

                                            {venda.data_venda &&
                                                <span >
                                                    {toDate(venda.data_venda)}
                                                </span>
                                            }

                                        </label>
                                    </div>
                                    <div>
                                        <label>Avaliadora:<span> {venda.avaliadora === venda.caixa ? '?' : venda.avaliadora}</span></label>
                                        <label>Caixa:<span> {venda.caixa}</span></label>
                                    </div>
                                    <div>
                                        <label>Unidade:<span> {unidades.find((element) => element.id == venda.unidade).nome}</span></label>
                                        <label>Código:<span> {venda.id}</span></label>
                                    </div>
                                </>
                            }>
                                <Table >
                                    <Th array={titles} />
                                    {venda.vendas_sub.map((vendaSub, index) =>
                                        <tr
                                            key={index}>

                                            <td>
                                                {vendaSub.nome_procedimento}
                                            </td>

                                            <td>
                                                {vendaSub.regiao?.substr(0, 30)}
                                            </td>
                                            <td>
                                                {parseFloat(vendaSub.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                            </td>

                                            <td
                                                className='assButton'
                                                style={{ backgroundColor: status[vendaSub.status].cor }}
                                                onClick={() => getAss(vendaSub)}
                                            >
                                                {vendaSub.status == 0 || vendaSub.SP == 'P'? '-' :
                                                    vendaSub.qnt_sessao - vendaSub.realizadas}
                                            </td>
                                            <td>
                                                {vendaSub.qnt_sessao}

                                            </td>

                                            <td>
                                                {vendaSub.id}
                                            </td>
                                        </tr>
                                    )}
                                </Table>
                            </Card>
                        )
                        : ''

                    }

                </ContainerC>


                : "Informe o cliente..."

            }



        </>
    )

}

/*

*/