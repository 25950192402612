import { useEffect, useState, useMemo } from 'react';
import React from 'react';
import { BiEdit } from 'react-icons/bi'
import { HiOutlineTrash } from "react-icons/hi";
import { MdSend } from "react-icons/md";
import { useParams } from 'react-router';

import { Form, Page } from '../a_model/Container';
import { Dialog } from '../a_model/Dialog';
import { SearchList, Table, Th } from '../a_model/Contents';
import { deletar, get, insert, update } from '../a_model/fetch';
import { Button, InputWithLabel } from '../a_model/Input';
export default function Home() {

  const { empresa } = useParams()
  const [text, setText] = useState(false)
  const [list, setList] = useState([[], []])
  const [search, setSearch] = useState('')
  const [step, setStep] = useState(0)
  const stepTitles = ['Convênio', 'Parceiros']


  useEffect(() => {
    get(`/get/parceiros/empresa/${empresa}`, desmembrar)
  }, [text])

  function desmembrar(result) {
    let resultDesmembrado = [[], []]

    result.forEach(item => {
      if (item.categoria === 1) {
        resultDesmembrado[0].push(item) // Categoria 1 vai para o primeiro array
      } else if (item.categoria === 2) {
        resultDesmembrado[1].push(item) // Categoria 2 vai para o segundo array
      }
    })

    setList(resultDesmembrado)
  }
  function dialog(value) {
    setText(value)
  }



  const titles = [
    { title: 'Nome' },
    { title: 'Ações' },
  ]



  function StepsRow({ item }) {
    const [stepRow, setStepRow] = useState(0)
    const steps = [
      <tr key={item.id} >
        <td style={{ maxWidth: '180px' }}>{item.nome}</td>
        <td style={{
          display: 'flex',
          justifyContent: 'space-around'
        }}>
          <div className='hover' onClick={() => setStepRow(stepRow === 0 ? 1 : 0)}>
            <BiEdit />
          </div>
          <div className='hover' onClick={() => handleDelete()}>
            <HiOutlineTrash />
          </div>
        </td>
      </tr>,
      <InputRow item={item} dialog={dialog} step={step} />
    ]

    function handleDelete() {

      let resp = window.confirm(`Confirma a exclusão de ${item.nome} da lista?`)
    
      if(resp){
        deletar(`/deletar/parceiros/id/${item.id}`, then)
     
      }
    }
    function then() {
      dialog({ text: 'Excluido', type: 0 })
    }
    return (
      steps[stepRow]
    )
  }


  const results = useMemo(() => {
    const lowerBusca = search.toLowerCase()
    return list[step].filter(item => item.nome ? item.nome.toLowerCase().includes(lowerBusca) : '');
  }, [search, list, step])

  return (
    <Form>
      <Dialog value={text} />

      <SearchList
        stepTitles={stepTitles}
        search={search}
        setSearch={setSearch}
        setStep={setStep}
        step={step}
      >

        <Table
          height='calc(100vh - 180px)'
        >
          <Th array={titles} />
          {results.map((item, index) =>
            <StepsRow item={item} />

          )}
          <InputRow step={step} dialog={dialog} />
        </Table>
      </SearchList>
    </Form>
  )
}

function InputRow({ item, step, dialog }) {
  const { empresa } = useParams()
  const [project, setProject] = useState(item || { nome: '', categoria: 1, empresa })
  useEffect(() => {
    if (!item) {
      setProject({ ...project, ['categoria']: step + 1 })
    }
  }, [step])
  function handleChange(e) {
    setProject({ ...project, [e.target.name]: e.target.value })

  }

  function save() {
    if (item) {

      update(`/editar/parceiros/id/${item.id}`, project, then)

    } else {
      insert(`/inserir/parceiros`, project, then)
    }

  }

  function then() {
    setProject({ ...project, ['categoria']: step + 1, ['nome']: '' })
    if (item) {
      dialog({ text: 'Alterado', type: 0 })
    } else {
      dialog({ text: 'Inserido', type: 0 })
    }
  }

  return (
    <tr>
      <td>
        <InputWithLabel
          placeholder='Novo Registro'
          name='nome'
          width='100%'
          value={project.nome}
          handleOnChange={handleChange}
        />
      </td>
      <td>
        <Button click={() => save()}>
          <MdSend />
        </Button>
      </td>
    </tr>
  )
}