import React, { useState, useEffect, useContext, useMemo } from "react";
import { Caixa, Card, Form } from "../../a_model/Container";
import { Table, Th } from "../../a_model/Contents";
import { Modal } from '../../a_model/Modal'
import { get } from "../../calendar/f_aux/fetch";
import Context from "../../calendar/Context";
import './Caixa.scss'
import { toCash, toDate } from "../../a_model/functions";
import VendasForm from "../../event/b_forms/VendasForm";

export default function RelatorioRecompra() {
    const { data, refresh } = useContext(Context)

    const [cliente, setCliente] = useState(false)

    const unidades = [
        { id: '0', nome: 'Indefinido' },
        { id: 1, nome: 'Matriz' },
        { id: 3, nome: 'Goiânia Shopping' },
        { id: 4, nome: 'Orion' },
        { id: 5, nome: 'Campinas' },
        { id: 10, nome: 'Altamira' },
        { id: 11, nome: 'Paulinia' },
        { id: 13, nome: 'Brasília' },
    ]
    const [vendas, setVendas] = useState({
        primeira_venda: [],
        sum_primeira_venda: 0,
        count_primeira_venda: 0,
        recompra: [],
        sum_recompra: 0,
        count_recompra: 0,
    })
   
    useEffect(() => {
        get(`/getRelatorioRecompra/${data.data}/${data.fim}/${data.unidade}/${data.avaliadora}/${data.caixa}`, setVendas)

    }, [data, refresh])

    useEffect(() => {

        get(`/att_id_cliente_pg`, false)
    }, [])

    const [modalIsOpen, setIsOpen] = useState(false)

    function handleOpenModal(id_cliente) {
        setCliente({ id: id_cliente })
        setIsOpen(true)
    }


    const titles = [
        { width: '', title: 'Data' },

        { width: '', title: 'Nome' },
        { width: '', title: 'Unidade' },
        { width: '', title: 'Valor' },
        { width: '', title: 'Total' },
    ]
    const titles1 = [
        { width: '', title: 'Data' },
        { width: '', title: 'Nome' },
        { width: '', title: 'Valor' },
        { width: '', title: 'Total' },
    ]


    return (

        <Caixa>
            <div>
                <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Primeira Compra</h2>
                <Table
                    footer={`Total: ${vendas.count_primeira_venda} - ${toCash(vendas.sum_primeira_venda)}`}
                >
                    <Th array={data.unidade == 100 ? titles : titles1} />
                    {vendas.primeira_venda.map((dataCard, index) =>
                        <tr key={index} className='hover' onClick={() => handleOpenModal(dataCard.id_cliente)}>
                            
                            <td className="">{toDate(dataCard.data)}</td>
                            <td style={{ maxWidth: '200px' }}>{dataCard.nome_cliente}</td>
                            {data.unidade == 100 && <td >{unidades.find((element) => element.id == dataCard.empresa).nome}</td>}
                            <td>{toCash(dataCard.total)}</td>
                       <td>{dataCard.total_compras}</td>
                        </tr>
                    )}

                </Table>
            </div>





            <div>
                <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Recompra</h2>
                <Table
                    footer={`Total: ${vendas.count_recompra} - ${toCash(vendas.sum_recompra)}`}
                >
                    <Th array={data.unidade == 100 ? titles : titles1} />
                    {vendas.recompra.map((dataCard, index) =>
                        <tr key={index} className='hover' onClick={() => handleOpenModal(dataCard.id_cliente)}>
                            <td className="">{toDate(dataCard.data)}</td>
                            <td style={{ maxWidth: '200px' }}>{dataCard.nome_cliente}</td>
                            {data.unidade == 100 && <td >{unidades.find((element) => element.id == dataCard.empresa)?.nome}</td>}
                            <td>{toCash(dataCard.total)}</td>
                            <td>{dataCard.total_compras}</td>
                        </tr>
                    )}

                </Table>
            </div>


            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
            >

                {cliente ?
                    <VendasForm cliente={cliente} />
                    : ''}
            </Modal>
        </Caixa>

    )
}