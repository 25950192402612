import React, { useState, useEffect, useContext, useMemo } from "react";
import { Button } from "../../a_model/Input";
import '../c_layouts/Pdf.css'
import Context from "../Context";
import { produce } from 'immer'
import { MdClear } from "react-icons/md";
import { deletar, get, insert } from '../f_aux/fetch'
import { FormLine } from "../../a_model/Container";
import { LeftList, RightList } from "../../a_model/Contents";
export function BlockAten({ id_atendente }) {
    const { user, unidade } = useContext(Context)
    const [project, setProject] = useState([])
    const [blocks, setBlocks] = useState([])
    const [salas, setSalas] = useState([])
    const [refresh, setRefresh] = useState('')



    useEffect(() => {
        setProject([])
        if (id_atendente) {
            get(`/buscarBlockAtend/${id_atendente}`, then1)
            function then1(resp2) {
               
                setBlocks(resp2)
                get(`/buscarSalas/${unidade}`, then2)
                function then2(resp3) {
                 
                    setSalas(produce(resp3, draft => {
                        resp2.forEach(block => {
                            const index = draft.findIndex(element => element.id === block.chave);
                            if (index !== -1) {
                                draft.splice(index, 1); // Remove a sala bloqueada
                            }else{
                           
                            }
                        });
                    }))
                }
            }

        }
    }, [id_atendente, refresh])

    function incluir(item, index) {
        setProject(produce(project, draft => {
            draft.push(item)
        }))
        setSalas(produce(salas, draft => {
            draft.splice(index, 1)
        }))
    }

    return (
        <FormLine>
            <RBar salas={salas} incluir={incluir} />
            <RightList>

                <>
                    <div>
                        <h2>Bloquear:</h2>
                        <ul>
                            {project.map((block, index) =>
                                <li key={index}>
                                    {block.nome}
                                </li>
                            )}
                        </ul>
                    </div>

                    <div>
                        <h2>Bloqueados: ({blocks.length})</h2>
                        <ul>
                            {blocks.map((block, index) =>
                                <li key={index}>
                                    {block.nome}
                                    <div onClick={() => deletar(`/deleteBlockAtend/${block.id}`, setRefresh)
                                    }><MdClear /></div>
                                </li>
                            )}
                        </ul>
                    </div>

                    <footer>
                        <Button
                            color='#174fb6'
                            click={() => insert('/insertBlockAtend', { id_atendente, project }, setRefresh)
                            }
                            value='Salvar'
                        />
                        <Button
                            color='#222'
                            click={() => setProject([])}
                            value='Limpar'
                        />
                    </footer>
                </>

            </RightList>


        </FormLine>
    )

}


function RBar({ salas, incluir }) {
    const [search, setSearch] = useState('');
    const results = useMemo(() => {
        const lowerBusca = search.toLowerCase()
        return salas.filter(sala => (sala.nome.toLowerCase().includes(lowerBusca)));
    }, [search, salas])
    return (
        <LeftList>

            <input
                placeholder='Pesquisar...'
                onChange={(e) => setSearch(e.target.value)}
            />
            <ListItem
                salas={salas}
                incluir={incluir}
                results={results}
                search={search}
            />

        </LeftList>
    );
}

function ListItem({ results, incluir, salas }) {

    return (
        <ul >
            {results.map((result, index) => (

                <li key={index} onClick={() => incluir(result, salas.indexOf(result))}>
                    {result.nome || '-'}
                </li>

            ))}
        </ul>
    );
}