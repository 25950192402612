import { useState, useContext, useEffect, useMemo } from 'react'
import { InputText, InputCash, Button, InputTime, LabelText, LabelText4, InputDate, InputDateC, Select, InputNumber, SelectC, InputSearchButton, InputFloat, LabelText3 } from '../../a_model/Input'
import './InputDay.scss'
import { BsArrowReturnLeft, BsTrash } from 'react-icons/bs'
import { AiOutlineFileAdd, AiOutlineForm, AiOutlineCarryOut, AiOutlineLock, AiOutlineSwap } from 'react-icons/ai'
import { get, insert, update, updateParams } from '../f_aux/fetch'
import Context from '../Context'
import { FormLine } from "../../a_model/Container";
import { LeftList2, RightList2, Table, Th } from "../../a_model/Contents";
import { MdClear } from "react-icons/md";
import CadastrarAcompanhamento from './CadastrarAcompanhamento'
import { produce } from 'immer'
import { HiOutlineTrash } from "react-icons/hi";
import { CiAlarmOn, CiBadgeDollar } from "react-icons/ci";
import { toTel } from '../../a_model/functions'

export default function InputAgendado({ setInput, aula, limpar }) {
    const { setModal, user, inputClose, setIsEdit } = useContext(Context)
    const [agendados, setAgendados] = useState([])
    const [refresh, setRefresh] = useState('')
    const [id_leads, setId_leads] = useState(false)
    const [cad, setCad] = useState(false)
    const [sumValor, setSumValor] = useState(0.0)
    const [agendamento, setAgendamento] = useState(aula)
    const [stepDocente, setStepDocente] = useState(0)

    useEffect(() => {
        get(`/getClientesAgendadosIecb/${agendamento.id_aula}/${agendamento.data.substr(0, 10)}`, setAgendados)
        get(`/getSumValorAula/${agendamento.id_aula}`, setSumValor)


    }, [refresh])

    function then() {
        inputClose('Desativado', 0)
    }

    function attDocente(docente) {
        setStepDocente(0)
        setAgendamento({ ...agendamento, ['docente']: docente.id, ['nome_docente']: docente.nome })
    }

    function desativar() {
        let resp = window.confirm('Desativar esse agendamento?')
        if (resp) {
            update(`/desativarAgendaIecb`, aula, then)
        }
    }

    function thenCad() {
        setCad(false)
        setRefresh(Math.random())
    }

    const tipos = [
        '',
        <div className='tipo' style={{ color: '#d3912e' }}>A</div>,
        <div className='tipo' style={{ color: '#701d97' }}>M</div>
    ]
    const status = [
        <div className='status'><CiAlarmOn /></div>,
        <div className='status'><CiBadgeDollar color='#1c992d' /></div>,
    ]
    function setToReply() {
        setIsEdit(aula)
        inputClose()
    }


    const stepsDocente = [
        <LabelText3
            style={{ maxWidth: '110px', marginBottom: '10px' }}
            header='Professora'
            onClick2={() => setStepDocente(1)}
        >
            {agendamento.nome_docente}
        </LabelText3>,
        <UpdateDocente
            agendamento={agendamento}
            attDocente={attDocente}
        />
    ]
    function removerAluno(aluno) {
        let resp = window.confirm(`Confirma a remoção da ${aluno.nome} desta aula ?`)
        if (resp) {
            update(`/updateAtivoIecb/alunos_iecb/0/${aluno.id}`, {}, setRefresh)
        }

    }

    return (
        !id_leads ?
            <div id='inputAgendadoIecb'>

                {cad &&
                    <InsertAluno
                        cad={cad}
                        setCad={setCad}
                        thenCad={thenCad}
                    />}
                <div>

                    <body style={{ marginRight: '20px' }}>

                        <LabelText4
                            style={{ maxWidth: '400px', marginBottom: '10px' }}
                            header="Curso"
                        >
                            {agendamento.nome_curso || agendamento.nome_aula}
                        </LabelText4>
                        {stepsDocente[stepDocente]}


                        <div className='inline1'>
                            <LabelText4
                                style={{ maxWidth: '110px', marginBottom: '10px' }}
                                header='Data'
                            >
                                {agendamento.data && agendamento.data.substr(0, 10).split('-').reverse().join('/')}
                            </LabelText4>
                            <LabelText4
                                style={{ maxWidth: '110px', margin: '0 10px 10px 10px' }}
                                header='Início'
                            >
                                {agendamento.hora}
                            </LabelText4>
                            <LabelText4
                                style={{ maxWidth: '110px', marginBottom: '10px' }}
                                header='Término'
                            >
                                {agendamento.hora_fim}
                            </LabelText4>

                        </div>
                        <div className='inline1'>
                            <LabelText4
                                style={{ maxWidth: '110px', marginBottom: '10px' }}
                                header='Valor'
                            >
                                {parseFloat(agendamento.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </LabelText4>
                            <LabelText4
                                style={{ maxWidth: '110px', margin: '0 10px 10px 10px' }}
                                header='Total'
                            >
                                {parseFloat(sumValor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </LabelText4>
                        </div>

                        {agendamento.nota &&
                            <LabelText4
                                style={{ maxWidth: '110px', marginBottom: '10px' }}
                                header='Anotação'
                            >
                                {agendamento.nota}
                            </LabelText4>
                        }
                        <LabelText
                            header='Código'
                        >
                            {agendamento.id_aula}
                        </LabelText>
                    </body>

                    <FormLine>
                        <RBar
                            setId_leads={setId_leads}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            usuario={user}
                            id_aula={agendamento.id_aula}
                            valor={agendamento.valor}
                            setCad={setCad}
                            data={agendamento.data}
                        />
                        <RightList2>
                            <div>
                                <h2>Agendados: ({agendados.length})</h2>
                                <ul className='ulAlunos'>
                                    {agendados.map((aluno, index) =>
                                        <li key={index}>
                                            <span>
                                                {aluno.nome.substr(0, 30)}
                                            </span>
                                            {status[aluno.status]}
                                            {tipos[aluno.tipo]}
                                            {<div onClick={() => removerAluno(aluno)}><MdClear /></div>}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </RightList2>
                    </FormLine>

                </div >
                <footer style={{ display: 'flex' }}>
                    <Button
                        color="#2d4492"
                        value='Novo'
                        click={() => setModal(0)}
                    >
                        <AiOutlineFileAdd />
                    </Button>
                    <Button
                        color="#6c388f"
                        value='Replicar'
                        click={() => setToReply(aula)}
                    >
                        <AiOutlineSwap />
                    </Button>
                    <Button
                        color="#8f2828"
                        value='Desativar'
                        click={() => desativar()}
                    >
                        <BsTrash />
                    </Button>
                    <Button
                        color="#474747"
                        value='Voltar'
                        click={() => inputClose()}
                    >
                        <BsArrowReturnLeft />
                    </Button>
                </footer>
            </div >
            :
            <CadastrarAcompanhamento
                id_leads={id_leads}
                setId_leads={setId_leads}
                setRefresh={setRefresh}
                aula={aula}
            />
    )
}

function RBar({ setCad, id_aula, valor, refresh, data, usuario, setId_leads }) {
    const [search, setSearch] = useState('');
    const [result, setResult] = useState([])
    useEffect(() => {
        search.length > 1 &&
            get(`/getClientesAgendarIecb/${search}/${id_aula}`, setResult)

    }, [refresh, search])

    function incluir(aluno) {
        if (aluno.leads) {
            let resp = window.confirm('Deseja cadastrar esse lead?')
            resp && setId_leads(aluno.id)
        } else {
            setCad({ id_aluno: aluno.id, id_aula, data: data.substr(0, 10), usuario, valor, tipo: 1 })
            //insert(`/insertAluno`, { id_aluno: aluno.id, id_aula, usuario, valor, tipo: 1 }, setRefresh)
        }
    }

    function cadastrar() {
        setId_leads(1)
    }

    return (
        <LeftList2>

            <InputSearchButton
                cadastrar={cadastrar}
                search={search}
                setSearch={setSearch}
                button='Cadastrar'
                placeholder="Pesquisar aluno..."

            />
            <ListItem

                incluir={incluir}
                alunos={result}
                search={search}
            />

        </LeftList2>
    );
}

function ListItem({ alunos, incluir }) {

    return (
        <ul >
            {alunos.map((aluno, index) => (

                <li key={index} onClick={() => incluir(aluno)}>

                    <div style={{ color: aluno.leads ? '#4864e2' : '' }}>
                        {aluno.nome || '-'}

                    </div>

                    <div style={{ color: aluno.leads ? '#4864e2' : '' }}>

                        {toTel(aluno.telefone)}

                    </div>
                </li>

            ))}
        </ul>
    );
}

function InsertAluno({ cad, setCad, thenCad }) {

    function handleChange(e) {
        setCad({ ...cad, [e.target.name]: e.target.value })
    }
    const tipos = [
        { id: 1, nome: 'Aluna' },
        { id: 2, nome: 'Modelo' },
    ]
    return (
        <div id='cad' >
            <span>Selecione a categoria</span>
            <Select
                options={tipos}
                handleOnChange={handleChange}
                name='tipo'
                value={cad.tipo}
            />
            <div style={{ display: 'flex' }}>
                <Button
                    value='Agendar'
                    color='#555'
                    click={() => insert(`/insertAluno`, cad, thenCad)}
                />
                <Button
                    color='#992222'
                    value='Cancelar'
                    click={() => setCad(false)}
                />
            </div>

        </div>
    )

}

function UpdateDocente({ agendamento, attDocente }) {
    const [docentes, setDocentes] = useState([])

    useEffect(() => {
        get(`/getDocentesIecb/1`, setDocentes)
    }, [])

    function alterar(e) {
        let resp = window.confirm(`Definir ${docentes.find((element) => element.id == e.target.value).nome} como professora desta aula? `)
        if (resp) {
            update(`/editar/aulas_iecb/id/${agendamento.id_aula}`, { docente: e.target.value }, then)
        }
        function then() {
            attDocente(docentes.find((element) => element.id == e.target.value))
        }
    }

    return (
        <SelectC
            text='Professora'
            options={docentes}
            value={agendamento.docente}
            handleOnChange={alterar}
        />
    )
}
